import { recordGroupKey } from 'components/reporting/dataFetcher';

export function groupRecords(records, dimensions, customDimensions, scoreVersionIds) {
  const groupKeys = [
    ...dimensions,
    ...scoreVersionIds.map((id) => `rank::${id}`),
    ...customDimensions.map((dimension) => `customSegment::${dimension}`),
  ];

  // NOTE: Grouping by keys in order to remove duplicate score data
  const grouped = records.reduce((obj, record) => {
    const key = recordGroupKey(record, groupKeys);

    // eslint-disable-next-line no-param-reassign
    obj[key] = obj[key] ? [...obj[key], record] : [record];

    return obj;
  }, {});

  return Object.values(grouped).map((group) => {
    const combined = group.reduce((bucketed, record) => {
      const scoreProperties = scoreVersionIds.reduce((obj, id) => {
        const scoreKey = `score::${id}`;
        const excellentSpendKey = `excellentSpend::${id}`;

        return {
          [scoreKey]: [...bucketed[scoreKey], ...record[scoreKey]],
          [excellentSpendKey]: bucketed[excellentSpendKey] + record[excellentSpendKey],
        };
      }, {});

      return {
        ...bucketed,
        assetIds: [...bucketed.assetIds, ...record.assetIds],
        postIds: [...bucketed.postIds, ...record.postIds],
        spend: bucketed.spend + record.spend,
        ...scoreProperties,
      };
    });

    // NOTE: Having multiple queries means we can get the same post's score in multiple result sets.
    // This logic will remove the duplicates by associated post id and then sum the scores.
    const scoreProperties = scoreVersionIds.reduce((obj, id) => {
      const scoreKey = `score::${id}`;

      return {
        [scoreKey]: [...new Set(combined[scoreKey])].reduce((sum, score) => sum + parseFloat(score.split('::')[1]), 0),
      };
    }, {});

    // NOTE: Removing duplicate asset and post ids to get accurate counts
    return {
      ...combined,
      assetIds: [...new Set(combined.assetIds)],
      postCount: (new Set(combined.postIds)).size,
      ...scoreProperties,
    };
  });
}
