import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Widget,
  WidgetContent,
} from '@duik/it';
import TextStepper from 'components/molecules/TextStepper';
import CardHeader from 'components/reusable/CardHeader';
import FormSubmit from 'components/reusable/FormSubmit';
import { HiddenInput } from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';
import {
  useViewPage,
} from 'utilities/mixpanel';

const handleProps = PropTypes.shape({
  adAccountId: PropTypes.string,
  adAccountName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
});

export const propTypes = {
  authId: PropTypes.string.isRequired,
  formUrl: PropTypes.string.isRequired,
  handles: PropTypes.arrayOf(handleProps).isRequired,
  header: PropTypes.shape({
    currentStep: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};

function HandleRow(props) {
  const {
    callback,
    handle,
    selectedHandles,
  } = props;

  const {
    adAccountId,
    adAccountName,
    label,
    value,
  } = handle;

  useViewPage();

  const adAccountLabel = `${adAccountName} (${adAccountId})`;
  const handleLabel = `${label} (${value})`;

  return (
    <tr onClick={() => callback(handle)}>
      <td>
        <Checkbox
          checked={ArrayHelper.objectInArray(selectedHandles, handle)}
          onChange={() => callback(handle)}
        />
      </td>
      <td>{ adAccountLabel }</td>
      <td>{ handleLabel }</td>
    </tr>
  );
}
HandleRow.propTypes = {
  callback: PropTypes.func.isRequired,
  handle: handleProps.isRequired,
  selectedHandles: PropTypes.arrayOf(handleProps).isRequired,
};

function renderSelectedHandle(handle) {
  const {
    adAccountId,
    adAccountName,
    label,
    value,
  } = handle;

  return (
    <div key={value}>
      <HiddenInput name="accounts[][id]" value={adAccountId} />
      <HiddenInput name="accounts[][name]" value={adAccountName} />
      <HiddenInput name="accounts[][handles][][id]" value={value} />
      <HiddenInput name="accounts[][handles][][name]" value={label} />
    </div>
  );
}

function renderSelectedHandles(selectedHandles) {
  return selectedHandles.map((handle) => renderSelectedHandle(handle));
}

function formIncomplete(selectedHandles) {
  return !selectedHandles.length > 0;
}

class HandleSelect extends React.Component {
  constructor(props) {
    super(props);

    const { handles } = props;
    // Check if account name without spaces is the handle
    const selectedHandles = handles.filter(({ adAccountName, label }) => adAccountName.replace(/ /g, '').toLowerCase() === label.toLowerCase());

    this.state = { selectedHandles };
  }

  selectHandle(handle) {
    this.setState((prevState) => {
      let { selectedHandles } = prevState;
      selectedHandles = ArrayHelper.withOrWithoutObject(selectedHandles, handle);

      return { selectedHandles };
    });
  }

  render() {
    const {
      authId,
      formUrl,
      handles,
      header,
    } = this.props;

    const {
      selectedHandles,
    } = this.state;

    const subheader = (
      <div>
        <div>Please select the handle(s) per ad account that you&apos;d like to connect.</div>
        <div>Handles with the same name as the ad account will be selected by default.</div>
        <div>Only ad accounts with at least 1 Twitter handle will be shown below.</div>
      </div>
    );

    return (
      <div>
        <Widget>
          <TextStepper currentStep={header.currentStep} steps={header.steps} />
          <CardHeader header="Twitter Handles" subHeader={subheader} />
          <form action={formUrl} id="handleSelect" method="get">
            <HiddenInput name="auth_id" value={authId} />
            { renderSelectedHandles(selectedHandles) }
            <table className="card-table twitterHandleSelectTable">
              <thead>
                <tr>
                  <th aria-label="Handle Checkbox" />
                  <th>Ad Account (API ID)</th>
                  <th>Handle (API ID)</th>
                </tr>
              </thead>
              <tbody>
                {
                  handles.map((handle) => (
                    <HandleRow
                      key={handle.value}
                      callback={(h) => this.selectHandle(h)}
                      handle={handle}
                      selectedHandles={selectedHandles}
                    />
                  ))
                }
              </tbody>
            </table>
          </form>
          <WidgetContent>
            <div className="u-marginAbove u-textAlignRight">
              <FormSubmit
                disabled={formIncomplete(selectedHandles)}
                form="handleSelect"
                label="Select Handles"
                trackingLabel="Whitelist Twitter Handles for Ad Accounts"
                primary
              />
            </div>
          </WidgetContent>
        </Widget>
      </div>
    );
  }
}

HandleSelect.propTypes = propTypes;

export default HandleSelect;
