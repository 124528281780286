import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table, { propTypes as tableProps } from 'components/molecules/Table';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import { useViewPage } from 'utilities/mixpanel';

const propTypes = {
  addMixpanelData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  table: PropTypes.shape({
    headers: tableProps.headers,
    rows: tableProps.rows,
  }).isRequired,
};

function Confirmation({
  addMixpanelData,
  formData: {
    id,
    url,
  },
  table: {
    headers,
    rows: propRows,
  },
}) {
  useViewPage();
  useEffect(() => {
    addMixpanelData({ totalBrandPages: propRows.length });
  }, []);

  const rows = propRows.map((propRow) => {
    const row = propRow;
    row.key = `${row.data.businessManagerApiId}_${row.data.apiId}`;
    return row;
  });

  const brandPages = rows.map((row) => row.data);

  return (
    <div>
      <Table
        headers={headers}
        rows={rows}
        title="Account Connections"
      />
      <form action={url} id={id} method="post">
        <AuthenticityTokenInput />
        {
          brandPages.map((page) => (
            <React.Fragment key={`${page.businessManagerApiId}_${page.apiId}`}>
              <HiddenInput
                name="business_centers[][api_id]"
                value={page.businessManagerApiId}
              />
              <HiddenInput
                name="business_centers[]brand_pages[][api_id]"
                value={page.apiId}
              />
              <HiddenInput
                name="business_centers[]brand_pages[][name]"
                value={page.name}
              />
              <HiddenInput
                name="business_centers[]brand_pages[][currency]"
                value={page.currency}
              />
              <HiddenInput
                name="business_centers[]brand_pages[][market]"
                value={page.market.value}
              />
            </React.Fragment>
          ))
        }
      </form>
    </div>
  );
}

Confirmation.propTypes = propTypes;

export default Confirmation;
