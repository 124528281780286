import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  RadioGroup,
  TextInput,
  Toggle,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import {
  getAuthenticityToken,
  patch,
  post,
} from 'utilities/requests';
import {
  adminCompanyApiAuthorizationPath,
  adminCompanyApiAuthorizationsPath,
} from 'utilities/routes';

const propTypes = {
  companyId: PropTypes.number.isRequired,
  partners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  active: PropTypes.bool,
  apiId: PropTypes.number,
  label: PropTypes.string,
  partnerId: PropTypes.number,
  scope: PropTypes.string,
  webhookUrl: PropTypes.string,
};

const defaultProps = {
  active: true,
  apiId: undefined,
  label: '',
  partnerId: undefined,
  scope: 'content',
  webhookUrl: '',
};

const CONTENT_OPTION = 'content';
const REPORTING_OPTION = 'reporting';

function AuthorizationForm({
  active: initialActive,
  apiId,
  companyId,
  label: initialLabel,
  partnerId: initialPartnerId,
  partners,
  scope: initalScope,
  webhookUrl: initialWebhookUrl,
}) {
  const radioOptions = [
    {
      label: 'Content',
      value: CONTENT_OPTION,
      disabled: initialPartnerId,
    },
    {
      label: 'Reporting',
      value: REPORTING_OPTION,
      disabled: initialPartnerId,
    },
  ];

  const [webhookUrl, setWebhookUrl] = useState(initialWebhookUrl);
  const [label, setLabel] = useState(initialLabel);
  const [partner, setPartner] = useState(
    initialPartnerId ? partners.find((({ value }) => initialPartnerId === value)) : null,
  );
  const [scope, setScope] = useState(
    radioOptions.find(
      ({ value }) => initalScope === value,
    ).value,
  );
  const [isActive, setIsActive] = useState(initialActive);

  const sharedFormData = () => {
    const formData = new FormData();
    formData.append('authenticity_token', getAuthenticityToken());
    formData.append('label', label || '');
    formData.append('partner_id', partner.value);
    formData.append('scopes', scope);
    formData.append('webhook_url', webhookUrl || '');
    return formData;
  };

  const onCreate = async () => {
    const response = await post(
      adminCompanyApiAuthorizationsPath(companyId, apiId), sharedFormData(),
    );

    if (response.data.error) {
      addToast(response.data.error, { type: 'error' });
    } else {
      addToast('API Access Token Successfully Generated!');
      window.location.href = adminCompanyApiAuthorizationsPath({ company_id: companyId });
    }
  };

  const onUpdate = async () => {
    const formData = sharedFormData();
    formData.append('active', isActive ? 'active' : 'inactive');

    const response = await patch(adminCompanyApiAuthorizationPath(companyId, apiId), formData);

    if (response.data.error) {
      addToast(response.data.error, { type: 'error' });
    } else {
      addToast('API Access Token Successfully Generated!');
      window.location.href = adminCompanyApiAuthorizationsPath({ company_id: companyId });
    }
  };

  return (
    <div className="col-12">
      <Card>
        <div className="u-flexColumn u-gap-16">
          <div className="u-flexRow u-justifySpaceBetween">
            { initialPartnerId && (
              <Toggle
                checked={isActive}
                label="Active"
                right
                onChange={() => setIsActive(!isActive)}
              />
            ) }
          </div>
          <Divider />
          <h5>API Scope</h5>
          <RadioGroup
            disabled={initialPartnerId}
            label="API Scope"
            name="apiScopeRadioGroup"
            options={radioOptions}
            value={scope}
            horizontal
            onChange={(e) => setScope(e.target.value)}
          />
          <Dropdown
            label="Partner"
            menuProps={{ size: 'medium' }}
            options={partners}
            selected={partner}
            size="medium"
            onChange={(opt) => setPartner(opt)}
          />
          <TextInput
            label="Label (optional)"
            name="label"
            size="medium"
            testId="labelInput"
            value={label}
            onChange={(value) => setLabel(value)}
          />
          <TextInput
            label="Webhook URL (optional)"
            name="url"
            size="medium"
            testId="webhookUrlInput"
            value={webhookUrl}
            onChange={(value) => setWebhookUrl(value)}
          />
          <div className="u-marginTop-8 u-flexRow u-justifyEnd">
            <Button
              disabled={!partner}
              label={initialPartnerId ? 'Update' : 'Generate'}
              testId="actionAccessToken"
              variant="primary"
              onClick={initialPartnerId ? onUpdate : onCreate}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
AuthorizationForm.propTypes = propTypes;
AuthorizationForm.defaultProps = defaultProps;

export default AuthorizationForm;
