import React from 'react';
import PropTypes from 'prop-types';
import PostInfo, { propTypes as postInfoPropTypes } from 'components/admin/review_queue/post_info/PostInfo';
import ChallengeDetails from 'components/internal/review/ChallengeDetails';
import CheckDetails from 'components/internal/review/CheckDetails';
import CheckGuideline from 'components/internal/review/CheckGuideline';
import CheckReviewGuides from 'components/internal/review/CheckReviewGuides';
import { checkProps } from 'components/internal/review/shared';
import { editInternalReviewChallengePath } from 'utilities/routes';
import styles from './ChallengeDisplay.module.css';

const propTypes = {
  challenge: PropTypes.shape({
    check: checkProps.isRequired,
    id: PropTypes.number.isRequired,
    postInfo: PropTypes.shape(postInfoPropTypes).isRequired,
  }).isRequired,
  onPassedChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  passed: PropTypes.bool,
  onAssetReady: PropTypes.func,
};

const defaultProps = {
  isDisabled: false,
  onAssetReady: undefined,
  passed: undefined,
};

function ChallengeDisplay({
  challenge,
  isDisabled,
  onAssetReady,
  onPassedChange,
  passed,
}) {
  const {
    check,
    postInfo,
  } = challenge;

  return (
    <div className={styles.main}>
      <PostInfo
        {...postInfo}
        onAssetReady={onAssetReady}
      />
      <div className={styles.right}>
        <div className="u-flexColumn u-gap-24">
          <CheckDetails
            check={check}
            url={editInternalReviewChallengePath(challenge.id)}
          />
          <CheckGuideline
            check={check}
            isDisabled={isDisabled}
            passed={passed}
            onPassedChange={onPassedChange}
          />
          <ChallengeDetails challenge={challenge} />
          <CheckReviewGuides
            check={check}
            initialIsOpen={false}
          />
        </div>
      </div>
    </div>
  );
}

ChallengeDisplay.propTypes = propTypes;
ChallengeDisplay.defaultProps = defaultProps;

export default ChallengeDisplay;
