import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Button,
  TextArea,
  Tooltip,
} from '@makeably/creativex-design-system';
import styles from './ChallengeControls.module.css';

const propTypes = {
  isEscalated: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onEscalate: PropTypes.func.isRequired,
  onEvaluate: PropTypes.func.isRequired,
  onJustificationChange: PropTypes.func.isRequired,
  isBusy: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isEscalating: PropTypes.bool,
  justification: PropTypes.string,
  onClose: PropTypes.func,
};

const defaultProps = {
  isBusy: false,
  isDisabled: false,
  isEscalating: false,
  justification: '',
  onClose: undefined,
};

function ChallengeControls({
  isBusy,
  isDisabled,
  isEscalated,
  isEscalating,
  isPending,
  justification,
  onClose,
  onEscalate,
  onEvaluate,
  onJustificationChange,
}) {
  return (
    <div className="u-flexRow u-justifyEnd u-alignCenter u-gap-8">
      { onClose && (
        <Button
          label="Close"
          variant="tertiary"
          onClick={() => onClose()}
        />
      ) }
      { isPending && (
        <>
          <Tooltip label="Escalate if you are unsure of a score" />
          <ActionButton
            active={isBusy && isEscalating}
            disabled={isDisabled}
            label="Escalate"
            variant="secondary"
            onClick={onEscalate}
          />
        </>
      ) }
      { (isPending || isEscalated) && (
        <div className={styles.pending}>
          <TextArea
            disabled={isDisabled}
            size="large"
            value={justification}
            onChange={onJustificationChange}
          />
          <ActionButton
            active={isBusy && !isEscalating}
            disabled={isDisabled}
            label="Evaluate"
            onClick={onEvaluate}
          />
        </div>
      ) }
    </div>
  );
}

ChallengeControls.propTypes = propTypes;
ChallengeControls.defaultProps = defaultProps;

export default ChallengeControls;
