import React from 'react';
import PropTypes from 'prop-types';
import { checkProps } from 'components/internal/review/shared';
import ReviewGuide from 'components/internal/review_guides/ReviewGuide';

const propTypes = {
  check: checkProps,
  initialIsOpen: PropTypes.bool,
};

const defaultProps = {
  check: {},
  initialIsOpen: true,
};

function CheckReviewGuides({ check, initialIsOpen }) {
  const { reviewGuides } = check;

  if (!reviewGuides?.length) return null;

  return (
    <div className="u-flexColumn u-gap-24">
      { reviewGuides.map((guide) => (
        <ReviewGuide
          key={guide.id}
          guide={guide}
          initialIsOpen={initialIsOpen}
        />
      )) }
    </div>
  );
}

CheckReviewGuides.propTypes = propTypes;
CheckReviewGuides.defaultProps = defaultProps;

export default CheckReviewGuides;
