import React from 'react';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import { useViewPage } from 'utilities/mixpanel';
import {
  accountSetupDisplayAdsAccessIndexPath,
  newSettingsLinkedPlatformAccountPath,
} from 'utilities/routes';

function EnableDisplayAds() {
  useViewPage();
  return (
    <Card>
      <h5>Enable Display Ads</h5>
      <div className="t-subtitle u-marginTop-8">
        Grant CreativeX access to your DV360 Display Ads
      </div>
      <div className="t-body-1 u-marginTop-16">
        Click Continue to be redirected to Display and Video 360 in order to grant CreativeX
        Partner Access
      </div>
      <div className="u-flexRow u-justifyFlexEnd u-marginAboveLg">
        <a href={newSettingsLinkedPlatformAccountPath()}>
          <Button label="Skip" variant="tertiary" />
        </a>
        <a href={accountSetupDisplayAdsAccessIndexPath()}>
          <Button label="Continue" />
        </a>
      </div>
    </Card>
  );
}

export default EnableDisplayAds;
