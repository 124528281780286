import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  BasicModal,
  CloseButton,
} from '@makeably/creativex-design-system';
import ChallengeControls from 'components/internal/review/ChallengeControls';
import ChallengeDisplay from 'components/internal/review/ChallengeDisplay';
import LoadingDisplay from 'components/internal/review/LoadingDisplay';
import {
  isChallengeDisabled,
  postChallengeEscalate,
  patchChallengeUpdate,
} from 'components/internal/review/shared';
import { addErrorToast } from 'components/organisms/Toasts';
import { get } from 'utilities/requests';
import { evaluationPropsInternalReviewChallengePath } from 'utilities/routes';

const propTypes = {
  canEvaluate: PropTypes.bool.isRequired,
  canEvaluateEscalated: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  id: PropTypes.number,
};

const defaultProps = {
  id: undefined,
};

function ChallengeModal({
  canEvaluate,
  canEvaluateEscalated,
  id,
  onClose,
  onComplete,
}) {
  const [challenge, setChallenge] = useState(null);
  const [justification, setJustification] = useState();
  const [justificationEdited, setJustificationEdited] = useState(false);
  const [passed, setPassed] = useState();
  const [isBusy, setIsBusy] = useState(true);
  const [isEscalating, setIsEscalating] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const isLoaded = Boolean(challenge);
  const challengeDisabled = isChallengeDisabled(challenge, canEvaluate, canEvaluateEscalated);
  const isDisabled = challengeDisabled || isBusy || !isReady;
  const checkPassed = challenge?.check?.passed;
  const changeJustification = challenge?.changeJustification ?? '';
  const noChangeJustification = challenge?.noChangeJustification ?? '';

  useEffect(() => {
    const updateChallenge = async () => {
      const { data, isError } = await get(evaluationPropsInternalReviewChallengePath(id));

      if (!isError) {
        setChallenge(data);
      } else {
        addErrorToast(`Could not load challenge ${id}`);
      }
    };

    setIsBusy(false);
    setIsReady(false);
    setChallenge(null);
    setJustificationEdited(false);
    if (id) updateChallenge(id);
  }, [id]);

  useEffect(() => {
    setPassed(checkPassed);
  }, [checkPassed]);

  useEffect(() => {
    setJustification(noChangeJustification);
  }, [noChangeJustification]);

  useEffect(() => {
    const changed = passed !== checkPassed;
    if (!justificationEdited) {
      setJustification(changed ? changeJustification : noChangeJustification);
    }
  }, [passed, checkPassed, changeJustification, noChangeJustification]);

  const handleJustificationChange = (value) => {
    setJustificationEdited(true);
    setJustification(value);
  };

  const handleClose = () => {
    setChallenge(null);
    onClose();
  };

  const handleEscalate = async () => {
    setIsEscalating(true);
    setIsBusy(true);
    await postChallengeEscalate(id);
    setChallenge(null);
    onComplete(id, true, challenge.state);
    setIsBusy(false);
  };

  const handleEvaluate = async () => {
    setIsEscalating(false);
    setIsBusy(true);
    await patchChallengeUpdate(id, passed, justification, challenge.reviewStartAt);
    setChallenge(null);
    onComplete(id, false, challenge.state);
    setIsBusy(false);
  };

  return (
    <BasicModal
      footer={(
        <ChallengeControls
          isBusy={isBusy}
          isDisabled={isDisabled}
          isEscalated={challenge?.state === 'escalated'}
          isEscalating={isEscalating}
          isPending={challenge?.state === 'pending'}
          justification={justification}
          onClose={handleClose}
          onEscalate={handleEscalate}
          onEvaluate={handleEvaluate}
          onJustificationChange={handleJustificationChange}
        />
      )}
      header={<CloseButton onClick={handleClose} />}
      isOpen={Boolean(id)}
      onClose={handleClose}
    >
      { isLoaded && (
        <ChallengeDisplay
          challenge={challenge}
          isDisabled={isDisabled}
          passed={passed}
          onAssetReady={() => setIsReady(true)}
          onPassedChange={setPassed}
        />
      ) }
      { !isLoaded && <LoadingDisplay /> }
    </BasicModal>
  );
}

ChallengeModal.propTypes = propTypes;
ChallengeModal.defaultProps = defaultProps;

export default ChallengeModal;
