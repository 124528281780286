import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import ItemDetails from 'components/internal/review/ItemDetails';
import { NULL_VALUE } from 'components/internal/shared';
import { arrayIf } from 'utilities/array';
import { editAdminReviewGuidelineCheckPath } from 'utilities/routes';
import { toDate } from 'utilities/string';
import styles from './ChallengeDetails.module.css';

const propTypes = {
  challenge: PropTypes.shape({
    challenger: PropTypes.string,
    checkReviewer: PropTypes.string,
    id: PropTypes.number,
    identicalAuditAssetId: PropTypes.number,
    identicalCheckId: PropTypes.number,
    identicalGuidelineId: PropTypes.number,
    reason: PropTypes.string,
    state: PropTypes.string,
  }),
};

const defaultProps = {
  challenge: undefined,
};

function getDetails(isReviewed) {
  return [
    {
      key: 'reviewer',
      label: 'Reviewer',
    },
    ...arrayIf(isReviewed,
      {
        key: 'passedBeforeReview',
        label: 'Passed Before Review',
      },
      {
        key: 'createdAt',
        label: 'Challenge Date',
      }),
    {
      key: 'reason',
      label: 'Reason',
    },
    {
      key: 'challenger',
      label: 'Challenger',
    },
    {
      key: 'userExemption',
      label: 'Exemption Request',
    },
    ...arrayIf(isReviewed,
      {
        key: 'challengeReviewer',
        label: 'Challenge Reviewer',
      },
      {
        key: 'passedAfterReview',
        label: 'Passed After Review',
      },
      {
        key: 'reviewedAt',
        label: 'Challenge Review Date',
      },
      {
        key: 'justification',
        label: 'Justification',
      }),
    {
      key: 'identicalAuditAssetId',
      label: 'Identical CreativeX ID',
    },
  ];
}

function getIdenticalAuditAsset(auditAssetId, guidelineId, checkId) {
  if (!auditAssetId) {
    return {
      label: 'N/A',
      value: NULL_VALUE,
    };
  }

  return {
    element: (
      <a
        href={editAdminReviewGuidelineCheckPath(guidelineId, checkId)}
        rel="noreferrer"
        target="_blank"
      >
        { auditAssetId }
      </a>
    ),
    value: auditAssetId,
  };
}

function getPassedIcon(value) {
  switch (value) {
    case true:
      return <Icon color="green" name="checkCircle" noWrapper />;
    default:
      return <Icon color="red" name="xCircle" noWrapper />;
  }
}

function getPassed(value) {
  return {
    element: (
      <div className={styles.passed}>
        { getPassedIcon(value) }
      </div>
    ),
    value: value ?? NULL_VALUE,
  };
}

function getItem({
  challenger,
  challengeReviewer,
  checkReviewer,
  createdAt,
  id,
  identicalAuditAssetId,
  identicalCheckId,
  identicalGuidelineId,
  justification,
  passedAfterReview,
  passedBeforeReview,
  reason,
  reviewedAt,
  userExemption,
}) {
  return {
    challenger: { value: challenger },
    challengeReviewer: { value: challengeReviewer ?? NULL_VALUE },
    createdAt: {
      label: toDate(new Date(createdAt), true),
      value: createdAt ?? NULL_VALUE,
    },
    id: { value: id },
    justification: { value: justification ?? NULL_VALUE },
    reason: { value: reason },
    reviewer: { value: checkReviewer },
    identicalAuditAssetId: getIdenticalAuditAsset(
      identicalAuditAssetId,
      identicalGuidelineId,
      identicalCheckId,
    ),
    passedAfterReview: getPassed(passedAfterReview),
    passedBeforeReview: getPassed(passedBeforeReview),
    reviewedAt: {
      label: toDate(new Date(reviewedAt), true),
      value: reviewedAt ?? NULL_VALUE,
    },
    userExemption: { value: userExemption ?? 'N/A' },
  };
}

function ChallengeDetails({ challenge }) {
  const isReviewed = challenge?.state === 'reviewed';
  const item = useMemo(() => getItem(challenge), [challenge]);

  return (
    <ItemDetails
      columnCount={2}
      details={getDetails(isReviewed)}
      item={item}
    />
  );
}

ChallengeDetails.propTypes = propTypes;
ChallengeDetails.defaultProps = defaultProps;

export default ChallengeDetails;
