import React, {
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
  Spinner,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { post } from 'utilities/requests';
import { taxonomySegmentOptionBulkUploadIndexPath } from 'utilities/routes';
import styles from './BulkUpload.module.css';

const EXCEL_TEMPLATE_URL = 'https://picassolabs.s3.amazonaws.com/files/CreativeX%20Custom%20Segment%20Bulk%20Upload%20Template.xlsx';
const GOOGLE_TEMPLATE_URL = 'https://docs.google.com/spreadsheets/d/1NF8Vac14-6chw3_8tp5FGTgNILt7RGQkJamBFohgk38/copy';

const propTypes = {
  dimensionId: PropTypes.number.isRequired,
};

function BulkUpload({ dimensionId }) {
  const [isLoading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);

  const handleUploadCsv = () => {
    hiddenFileInput.current.click();
  };

  const handleSubmitForm = async (e) => {
    if (e.target.files?.length !== 1) {
      return;
    }

    setLoading(true);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await post(taxonomySegmentOptionBulkUploadIndexPath(dimensionId), formData);

    addToast(data.message, { type: data.success ? 'success' : 'error' });
    setLoading(false);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card>
      <div className="u-flexRow u-justifySpaceBetween u-marginBottom-16 u-gap-24 u-alignCenter">
        <div>
          <h5 className="u-marginBottom-8">
            Bulk Upload Custom Values and Codes
          </h5>
          <div className="t-subtitle">
            Upload and pair custom values and codes in CreativeX with a CSV.
          </div>
        </div>
        <AddNewButton
          disabled={isLoading}
          label="Upload CSV"
          onClick={handleUploadCsv}
        />
        <input
          ref={hiddenFileInput}
          accept=".csv,.xlsx,.xls"
          name="file"
          style={{ display: 'none' }}
          type="file"
          onChange={handleSubmitForm}
        />
      </div>
      <Divider />
      <div className="u-flexRow u-gap-8 u-marginTop-16 u-marginBottom-16">
        <Button
          iconLeft="download"
          label="Download Template"
          url={EXCEL_TEMPLATE_URL}
          variant="secondary"
        />
        <a href={GOOGLE_TEMPLATE_URL} rel="noreferrer" target="_blank">
          <Button
            iconLeft="externalLink"
            label="Open in Google Sheets"
            variant="secondary"
          />
        </a>
      </div>
      <div className="u-marginBottom-16">
        You will receive an email if the upload has been successful.
      </div>
      <div className="u-marginBottom-16">
        <ul>
          <li>
            Do not edit any of the column headers or the file will not be accepted
          </li>
          <li>
            Remember to change your file into a &quot;.csv&quot; before uploading
          </li>
        </ul>
      </div>
      <div className={styles.list}>
        <ul>
          <li>
            To add a new custom value, enter it in the Value column (Column A) and leave
            the Code column (Column B) blank.
          </li>
          <li>
            To add a new custom code, enter it in the Code column (Column B) next to a value
            that already exists in the Value column (Column A).
          </li>
          <li>
            NOTE: If you want to add a code for a value that does not already exist, you must first
            add the value as a new value in a preceding row.
          </li>
        </ul>
      </div>
      <div className="u-marginBottom-8">
        Please reach out to&nbsp;
        <a href="mailto: support@creativex.com">support@creativex.com</a>
        &nbsp;if you have any issues with your upload.
      </div>
    </Card>
  );
}

BulkUpload.propTypes = propTypes;

export default BulkUpload;
