import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  Divider,
  MoreButton,
  MultipleDropdown,
  Search,
} from '@makeably/creativex-design-system';
import CodeModal from 'components/custom_segments/CodeModal';
import WarningModal from 'components/custom_segments/WarningModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { getCsv } from 'utilities/csv';
import { saveFile } from 'utilities/file';
import { getItemSortBy } from 'utilities/item';
import { searchItems } from 'utilities/itemSearch';
import {
  destroy,
  patch,
  post,
} from 'utilities/requests';
import {
  taxonomySegmentOptionCodesPath,
  taxonomySegmentOptionCodePath,
  newTaxonomySegmentOptionBulkUploadPath,
} from 'utilities/routes';
import { dateToString } from 'utilities/string';
import styles from './SegmentCodes.module.css';

const propTypes = {
  segmentId: PropTypes.number.isRequired,
  valuesWithCodes: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.number,
    value: PropTypes.string,
    valueId: PropTypes.number,
  })).isRequired,
};

const tableHeaders = [
  {
    key: 'code',
    label: 'Code',
  },
  {
    key: 'value',
    label: 'Value Name',
  },
  {
    key: 'createdAt',
    label: 'Date Added',
  },
  {
    key: 'actions',
    label: '',
    sortable: false,
  },
];

async function saveNewCode(name, value, segmentId) {
  const formData = new FormData();
  formData.append('code', name);
  formData.append('value_id', value);

  const response = await post(taxonomySegmentOptionCodesPath(segmentId), formData);
  return response.data;
}

async function updateCode(codeId, value, segmentId) {
  const formData = new FormData();
  formData.append('value_id', value);

  const response = await patch(taxonomySegmentOptionCodePath(segmentId, codeId), formData);
  return response.data;
}

async function deleteCode(codeId, segmentId) {
  const response = await destroy(taxonomySegmentOptionCodePath(segmentId, codeId));
  return response.data;
}

function downloadCsv(data) {
  const filteredData = data.filter(({ code }) => code);
  const csv = getCsv(filteredData, [{
    label: 'Code',
    key: 'code',
  }, {
    label: 'Value',
    key: 'value',
  }, {
    label: 'Date Added',
    key: 'createdAt',
  }]);
  saveFile('segment-codes.csv', csv);
}

function getActions(handleEdit, code, handleDelete) {
  return (
    <MoreButton
      menuSize="small"
      options={[{
        label: 'Edit',
        onClick: () => handleEdit(code),
      },
      {
        label: 'Delete',
        onClick: () => handleDelete(code.id),
      }]}
    />
  );
}

function getItems(valuesWithCodes, handleEdit, handleDelete) {
  return valuesWithCodes
    .filter(({ code }) => code)
    .map((code) => ({
      actions: {
        element: getActions(handleEdit, code, handleDelete),
      },
      code: { value: code.code },
      createdAt: { value: dateToString(code.createdAt) },
      id: { value: code.id },
      value: { value: code.value },
      valueId: { value: code.valueId },
    }));
}

function getValueOptions(valuesWithCodes) {
  const uniqueValues = new Map();
  valuesWithCodes.forEach(({ valueId, value }) => {
    uniqueValues.set(`${valueId}-${value}`, {
      value: valueId,
      label: value,
    });
  });
  return Array.from(uniqueValues.values());
}

function getMoreButtonOptions(data, segmentId) {
  return [
    {
      label: 'Download CSV',
      onClick: () => downloadCsv(data),
    },
    {
      label: 'Bulk CSV Upload',
      url: newTaxonomySegmentOptionBulkUploadPath(segmentId),
    },
  ];
}

function SegmentCodes({
  segmentId,
  valuesWithCodes,
}) {
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [editCode, setEditCode] = useState({});
  const [codeToDelete, setCodeToDelete] = useState(null);
  const [tableSort, setTableSort] = useState({
    asc: false,
    key: 'createdAt',
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const [search, setSearch] = useState('');
  const [hasAddedCode, setHasAddedCode] = useState(false);

  const valueOptions = useMemo(() => getValueOptions(valuesWithCodes), [valuesWithCodes]);

  const handleEditCode = (code) => {
    setEditCode(code);
    setIsCodeModalOpen(true);
  };

  const handleDeleteCode = async (codeId) => {
    setCodeToDelete(codeId);
    setIsWarningModalOpen(true);
  };

  const handleCodeSaveOrUpdate = async (name, value) => {
    let response;
    if (editCode.id) {
      setIsCodeModalOpen(false);
      setIsWarningModalOpen(true);
      setEditCode({
        ...editCode,
        valueId: value,
      });
    } else {
      response = await saveNewCode(name, value, segmentId);
      setHasAddedCode(true);
    }

    addToast(response.message, { type: response.success ? 'success' : 'error' });
  };

  const handleConfirmUpdateOrDelete = async () => {
    let response;
    if (codeToDelete) {
      response = await deleteCode(codeToDelete, segmentId);
    } else if (editCode.id) {
      response = await updateCode(editCode.id, editCode.valueId, segmentId);
    }
    addToast(response.message, { type: response.success ? 'success' : 'error' });
    window.location.reload();
  };

  const handleCancel = () => {
    if (hasAddedCode) {
      window.location.reload();
    }
    setEditCode({});
    setIsCodeModalOpen(false);
  };

  const handleCancelWarning = () => {
    setCodeToDelete(null);
    setEditCode({});
    setIsWarningModalOpen(false);
  };

  const handleUpdateSelections = (selected) => {
    if (selectedValues.includes(selected)) {
      setSelectedValues(selectedValues.filter((value) => value !== selected));
    } else {
      setSelectedValues([...selectedValues, selected]);
    }
  };

  const items = useMemo(() => {
    const selectedIds = selectedValues.map((value) => value.value);
    let filteredData = valuesWithCodes;
    if (selectedIds.length > 0) {
      filteredData = valuesWithCodes.filter(({ valueId }) => selectedIds.includes(valueId));
    }
    const itemData = getItems(filteredData, handleEditCode, handleDeleteCode);

    return searchItems(itemData, search, ['code', 'value']);
  }, [valuesWithCodes, selectedValues, search]);
  const sortedItems = useMemo(() => {
    if (tableSort) {
      const byKeyDir = getItemSortBy(tableSort.key, tableSort.asc);
      return items.slice().sort(byKeyDir);
    }
    return items;
  }, [items, tableSort]);

  return (
    <>
      <Card className="u-marginBottom-8">
        <div className="u-flexColumn u-gap-24">
          <div className="u-flexRow u-justifyBetween">
            <div className="u-flexRow u-alignEnd u-gap-8">
              <MultipleDropdown
                disabled={valuesWithCodes.length === 0}
                label="Values"
                options={valueOptions}
                selected={selectedValues}
                size="medium"
                search
                onChange={handleUpdateSelections}
              />
              <Search
                disabled={valuesWithCodes.length === 0}
                placeholder="Search"
                value={search}
                onChange={setSearch}
              />
            </div>
            <div className="u-flexRow u-alignCenter">
              <AddNewButton
                label="Add New Code"
                onClick={() => setIsCodeModalOpen(true)}
              />
              <Divider margin vertical />
              <MoreButton options={getMoreButtonOptions(valuesWithCodes, segmentId)} />
            </div>
          </div>
          <ItemsTable
            className={styles.table}
            headers={tableHeaders}
            items={sortedItems}
            sort={tableSort}
            onSortChange={setTableSort}
          />
        </div>
      </Card>
      <CodeModal
        existingCode={{
          code: editCode.code,
          valueId: editCode.valueId,
        }}
        isOpen={isCodeModalOpen}
        valueOptions={valueOptions}
        onCancel={handleCancel}
        onSave={handleCodeSaveOrUpdate}
      />
      <WarningModal
        isOpen={isWarningModalOpen}
        variant={editCode.id ? 'change' : 'delete'}
        onCancel={handleCancelWarning}
        onConfirm={handleConfirmUpdateOrDelete}
      />
    </>
  );
}

SegmentCodes.propTypes = propTypes;

export default SegmentCodes;
