import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@duik/it';
import Select from 'components/atoms/Select';
import {
  useViewPage,
} from 'utilities/mixpanel';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  adAccount: PropTypes.object.isRequired,
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        }),
      ),
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

function AdAccountAssignmentSection(props) {
  const {
    adAccount,
    onSelect,
    selectors,
  } = props;
  useViewPage();

  return (
    <Widget className="adAccountAssignmentSection">
      <div className="adAccountAssignmentSection-headerRow">
        <div className="asLabel">Ad Account Name</div>
        <div className="adAccountAssignmentSection-adAccountName">{ adAccount.name }</div>
      </div>
      <div className="adAccountAssignmentSection-dataRow">
        <div>
          <div className="asLabel">Ad Account Id</div>
          <div className="adAccount-detail">{ adAccount.apiId }</div>
        </div>
        <div>
          <div className="asLabel">Country</div>
          <div className="adAccount-detail">{ adAccount.country }</div>
        </div>
        <div className="spacer" />
      </div>
      <div className="adAccountAssignmentSection-formRow">
        {
          selectors.map((selector, idx) => {
            const menuPosition = idx === (selectors.length - 1) ? 'bottom-left' : 'bottom-right';

            return (
              <div key={selector.label}>
                <Select
                  {...selector} // eslint-disable-line react/jsx-props-no-spreading
                  activeOption={adAccount[selector.name]}
                  menuPosition={menuPosition}
                  block
                  searchable
                  onOptionClick={onSelect}
                />
              </div>
            );
          })
        }
      </div>
    </Widget>
  );
}

AdAccountAssignmentSection.propTypes = propTypes;

export default AdAccountAssignmentSection;
