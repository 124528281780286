import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { sortItemsArray } from 'utilities/array';
import {
  newAdminAuditReprocessPath,
  adminAuditReprocessPath,
} from 'utilities/routes';
import { toPercent } from 'utilities/string';
import {
  changePage,
  getPage,
  getParams,
} from 'utilities/url';
import styles from './ReprocessingJobs.module.css';

const reprocessProps = {
  assetCount: PropTypes.number,
  customerId: PropTypes.number,
  customerLabel: PropTypes.string,
  dateRun: PropTypes.string,
  dateRunLabel: PropTypes.string,
  id: PropTypes.number,
  postCount: PropTypes.number,
  reprocessName: PropTypes.string,
  reprocessQueue: PropTypes.string,
  reprocessType: PropTypes.string,
  ruleIds: PropTypes.arrayOf(PropTypes.string),
  state: PropTypes.string,
  userName: PropTypes.string,
};

const countProps = {
  count: PropTypes.number,
  id: PropTypes.number,
  jobStatus: PropTypes.string,
};

const processTypeLookupProps = {
  keep: PropTypes.string,
  remove: PropTypes.string,
  removeAllChecks: PropTypes.string,
  representation: PropTypes.string,
};

const propTypes = {
  canCreateReprocess: PropTypes.bool.isRequired,
  counts: PropTypes.arrayOf(PropTypes.shape(countProps)).isRequired,
  reprocessTypeLookup: PropTypes.shape(processTypeLookupProps).isRequired,
  reprocesses: PropTypes.arrayOf(PropTypes.shape(reprocessProps)).isRequired,
};

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'customerId',
    label: 'Customer (ID)',
  },
  {
    key: 'reprocessNameLabel',
    label: 'Name',
  },
  {
    key: 'reprocessQueueLabel',
    label: 'Job Priority',
  },
  {
    key: 'reprocessTypeLabel',
    label: 'Reprocess Type',
  },
  {
    key: 'ruleId',
    label: 'Rule IDs',
  },
  {
    key: 'dateRun',
    label: 'Date Run',
  },
  {
    key: 'primaryAuditPostCount',
    label: 'Post Count',
  },
  {
    key: 'assetCount',
    label: 'Asset Count',
  },
  {
    key: 'userFullName',
    label: 'User',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'percentCompleted',
    label: '% Completed',
  },
  {
    key: 'totalPending',
    label: 'Pending Posts',
  },
  {
    key: 'totalOverall',
    label: 'Total Overall',
  },
];

function getRuleIdsAsString(ruleIds) {
  if (!ruleIds || !ruleIds.sort || ruleIds.length === 0) {
    return 'N/A';
  }
  return ruleIds.sort().join(', ');
}

function generateCountMap(countsArray) {
  // create map from counts array to allow count lookup by reprocess id and job status
  // e.g. countMap.get(1).pending, countMap.get(1).total, etc
  return countsArray.reduce((countMap, jobCount) => {
    const {
      count,
      id: reprocessId,
      jobStatus,
    } = jobCount;

    const existing = countMap.get(reprocessId) ?? {};
    const existingTotal = existing.total ?? 0;

    return countMap.set(reprocessId, {
      ...existing,
      [jobStatus]: count,
      total: existingTotal + count,
    });
  }, new Map());
}

function getPercentCompleted(totalPending, totalOverall) {
  if (totalOverall) {
    return toPercent((totalOverall - totalPending) / totalOverall);
  }
  return 'N/A';
}

function getStatus(state, message) {
  const useDefaultMessage = (!message || message?.split(', ')?.length > 10);
  switch (state) {
    case 'processed':
      return 'Complete';
    case 'processed_with_issues':
      return `Partially Complete: ${useDefaultMessage ? 'See job details for more information.' : message}`;
    default:
      return 'In Progress';
  }
}

function reprocessQueueLabel(type) {
  switch (type) {
    case 'high':
      return 'High';
    default:
      return 'Standard';
  }
}

function getTotalPending(mapItem) {
  const pending = mapItem?.pending ?? 0;
  const inProgress = mapItem?.in_progress ?? 0;

  return pending + inProgress;
}

function getItems(counts, reprocesses, reprocessTypeLookup) {
  const countMap = generateCountMap(counts);

  return reprocesses.map((reprocess) => {
    const totalPending = getTotalPending(countMap.get(reprocess.id));
    const totalOverall = countMap.get(reprocess.id)?.total ?? 0;
    return ({
      id: { value: reprocess.id },
      customerId: {
        value: reprocess.customerId,
        element: <a href={`${adminAuditReprocessPath({ id: reprocess.id })}`}>{ reprocess.customerLabel }</a>,
      },
      reprocessNameLabel: { value: reprocess.name || '' },
      reprocessQueueLabel: { value: reprocessQueueLabel(reprocess.reprocessQueue) },
      reprocessTypeLabel: { value: reprocessTypeLookup[reprocess.reprocessType] },
      ruleId: { value: getRuleIdsAsString(reprocess.ruleIds) },
      dateRun: {
        value: reprocess.dateRun,
        label: reprocess.dateRunLabel,
      },
      primaryAuditPostCount: { value: reprocess.postCount },
      assetCount: { value: reprocess.assetCount },
      userFullName: { value: reprocess.userName },
      status: { value: getStatus(reprocess.state, reprocess.message) },
      percentCompleted: { value: getPercentCompleted(totalPending, totalOverall) },
      totalPending: { value: totalPending },
      totalOverall: { value: totalOverall },
    });
  });
}

function ReprocessingJobs({
  canCreateReprocess,
  counts,
  reprocesses,
  reprocessTypeLookup,
}) {
  const params = getParams(window);
  const [items] = useState(getItems(counts, reprocesses, reprocessTypeLookup));
  const [page, setPage] = useState(getPage(params));
  const [sort, setSort] = useState({
    key: 'dateRun',
    asc: false,
  });
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    setSortedItems(sortItemsArray(items, sort.key, sort.asc));
  }, [sort]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    changePage(newPage, params, window);
  };

  return (
    <Card className={`${styles.card} t-empty`}>
      <div className={`${styles.header}`}>
        <Button
          disabled={!canCreateReprocess}
          iconLeft="externalLink"
          label="Start an in-flight reprocess"
          url={newAdminAuditReprocessPath()}
          variant="secondary"
        />
      </div>
      <Divider />
      <ItemsTable
        emptyTableContent={(
          <div className={`${styles.empty} t-empty`}>
            <p>No in-flight reprocessing has been performed</p>
          </div>
        )}
        headers={headers}
        items={sortedItems}
        page={page}
        perPage={25}
        sort={sort}
        onPageChange={handlePageChange}
        onSortChange={setSort}
      />
    </Card>
  );
}

ReprocessingJobs.propTypes = propTypes;

export default ReprocessingJobs;
