import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  MaxWidthText,
} from '@makeably/creativex-design-system';
import DsTabs from 'components/molecules/DsTabs';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import {
  newAdminCompanyApiAuthorizationPath,
  editAdminCompanyApiAuthorizationPath,
} from 'utilities/routes';
import { titleize } from 'utilities/string';
import styles from './Configuration.module.css';

const propTypes = {
  apiAuths: PropTypes.arrayOf(PropTypes.shape({
    accessToken: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    partnerName: PropTypes.string.isRequired,
    scope: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    label: PropTypes.string,
    webhookUrl: PropTypes.string,
  })).isRequired,
  companyId: PropTypes.number.isRequired,
};

const tabs = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];
const tableHeaders = [
  {
    key: 'token',
    label: 'API Access Token',
  },
  {
    key: 'partner',
    label: 'Partner',
  },
  {
    key: 'scope',
    label: 'API Scope',
  },
  {
    key: 'label',
    label: 'Label',
  },
  {
    key: 'webhookUrl',
    label: 'API Webhook URL',
  },
];

function createTableRows(filteredRows, companyId) {
  return filteredRows.map((row) => {
    const {
      accessToken,
      scope,
      label,
      partnerName,
      webhookUrl,
      id,
    } = row;

    return {
      id: {
        value: id,
      },
      token: {
        element: (
          <a href={editAdminCompanyApiAuthorizationPath(
            companyId,
            id,
          )}
          >
            { accessToken }
          </a>
        ),
        value: accessToken,
      },
      partner: {
        value: partnerName,
      },
      scope: {
        value: titleize(scope),
      },
      label: {
        element: <MaxWidthText
          className={styles.label}
          text={label || 'N/A'}
        />,
        value: label,
      },
      webhookUrl: {
        element: <MaxWidthText
          className={styles.label}
          text={webhookUrl || 'N/A'}
        />,
        value: webhookUrl,
      },
    };
  });
}

function Configuration(
  { apiAuths, companyId },
) {
  const [tab, setTab] = useState(tabs[0]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    key: 'label',
    asc: false,
  });
  const [sortedRows, setSortedRows] = useState([]);

  useEffect(() => {
    setFilteredRows(apiAuths.filter(({ state }) => state === tab.value));
  }, [tab]);

  useEffect(() => {
    setTableRows(createTableRows(filteredRows, companyId));
  }, [filteredRows]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedRows(tableRows.sort(byKeyDir));
  }, [sort, tableRows]);

  const tabOptions = tabs.map((ruleType) => ({
    label: ruleType.label,
    onClick: () => setTab(ruleType),
  }));

  return (
    <div className="col-12">
      <Card>
        <div className="u-flexRow u-spaceBetween">
          <DsTabs currentTab={tab.label} tabs={tabOptions} variant="button" />
          <AddNewButton
            label="Generate Access Token"
            testId="generateAccessToken"
            url={newAdminCompanyApiAuthorizationPath({
              company_id: companyId,
            })}
          />
        </div>
        <ItemsTable
          emptyTableContent={(
            <div className={styles.emptyText} data-testid="emptyMessage">
              Click “+Generate Access Token” button to generate a new API Token
            </div>
          )}
          headers={tableHeaders}
          items={sortedRows}
          page={page}
          sort={sort}
          onPageChange={(newPage) => setPage(newPage)}
          onSortChange={setSort}
        />
      </Card>
    </div>
  );
}
Configuration.propTypes = propTypes;

export default Configuration;
