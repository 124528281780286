import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CopyButton } from '@makeably/creativex-design-system';
import { STATUS_NAMES } from 'components/admin/review_queue/shared';
import ItemDetails from 'components/internal/review/ItemDetails';
import { checkProps } from 'components/internal/review/shared';
import { getUniqueArrayDimension } from 'components/internal/shared';

const propTypes = {
  url: PropTypes.string.isRequired,
  check: checkProps,
};

const defaultProps = {
  check: {},
};

const details = [
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'id',
    label: 'Guideline Check ID',
  },
  {
    key: 'auditAssetId',
    label: 'CreativeX ID',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'campaignStatus',
    label: 'Campaign Status',
  },
  {
    key: 'reviewability',
    label: 'Reviewability',
  },
  {
    key: 'status',
    label: 'Status',
  },
];

// TODO: move into internal check retriever when updating queues
function processPretests(values) {
  return values.map((value) => (value ? 'Pre-Flight' : 'In-Flight'));
}

function getItem({
  auditAssetId,
  brands,
  channels,
  customer,
  id,
  markets,
  pretests,
  status,
}) {
  return {
    auditAssetId: { value: auditAssetId },
    brand: getUniqueArrayDimension(brands),
    campaignStatus: getUniqueArrayDimension(processPretests(pretests)),
    channel: getUniqueArrayDimension(channels),
    company: { value: customer },
    id: { value: id },
    market: getUniqueArrayDimension(markets),
    reviewability: { value: 'N/A' },
    status: { value: STATUS_NAMES[status] },
  };
}

function CheckDetails({ check, url }) {
  const item = useMemo(() => getItem(check), [check]);

  return (
    <div>
      <ItemDetails
        details={details}
        item={item}
      />
      <div className="u-flexRow u-alignCenter u-marginTop-16">
        <a href={url} rel="noreferrer" target="_blank">Link</a>
        <CopyButton text={url} />
      </div>
    </div>
  );
}

CheckDetails.propTypes = propTypes;
CheckDetails.defaultProps = defaultProps;

export default CheckDetails;
