import PropTypes from 'prop-types';
import {
  patch,
  post,
} from 'utilities/requests';
import {
  escalateInternalReviewChallengePath,
  internalReviewChallengePath,
} from 'utilities/routes';

export const checkProps = PropTypes.shape({
  auditAssetId: PropTypes.number.isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  customer: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  markets: PropTypes.arrayOf(PropTypes.string).isRequired,
  pretests: PropTypes.arrayOf(PropTypes.bool).isRequired,
  reviewGuides: PropTypes.arrayOf(
    PropTypes.shape({
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          creativeType: PropTypes.oneOf(['image', 'video']).isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
      helperText: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ).isRequired,
  rule: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  definition: PropTypes.string,
  description: PropTypes.string,
});

export function isChallengeDisabled(challenge, canEvaluate, canEvaluateEscalated) {
  if (challenge?.state === 'escalated') {
    return !canEvaluateEscalated;
  } else if (challenge?.state === 'pending') {
    return !canEvaluate;
  }
  return true;
}

export async function patchChallengeUpdate(id, passed, justification, reviewStartAt) {
  const formData = new FormData();
  formData.append('justification', justification);
  formData.append('passed', passed);
  formData.append('review_start_at', reviewStartAt);

  return patch(internalReviewChallengePath(id), formData);
}

export async function postChallengeEscalate(id) {
  return post(escalateInternalReviewChallengePath(id));
}
