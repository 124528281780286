import PropTypes from 'prop-types';
import {
  dateToString,
} from '@makeably/creativex-design-system';
import { track } from 'utilities/mixpanel';

export const customFilterProps = PropTypes.shape({
  dimension: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  optionName: PropTypes.string.isRequired,
  optionValues: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const dateOptionProps = PropTypes.shape({
  endDate: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const guidelineScoreProps = PropTypes.shape({
  guidelineNames: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number,
  name: PropTypes.string,
});

export const guidelinesDetailsProps = PropTypes.shape({
  id: PropTypes.number,
  markets: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
});

export const propertiesProps = PropTypes.shape({
  endDate: PropTypes.string,
  filters: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      }),
    ),
  ),
  metrics: PropTypes.arrayOf(PropTypes.string),
  segments: PropTypes.arrayOf(PropTypes.string),
  sort: PropTypes.shape({
    asc: PropTypes.bool,
    key: PropTypes.string,
  }),
  startDate: PropTypes.string,
  vizMetric: PropTypes.string,
});

export const scoreProps = PropTypes.shape({
  isDefault: PropTypes.bool.isRequired,
  metricLabels: PropTypes.shape({
    averageScore: PropTypes.string.isRequired,
    lowQualitySpend: PropTypes.string.isRequired,
    qualitySpend: PropTypes.string.isRequired,
    qualitySpendRate: PropTypes.string.isRequired,
    scoreRate: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      rank: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  versionId: PropTypes.number.isRequired,
});

export const typeLabels = {
  CreativeLifecycleReport: 'Creative Lifecycle',
  GuidelineReport: 'Guidelines',
  ScoreReport: 'Scores',
  TimePeriodReport: 'Time Periods',
};

// Used by the Score and Time Period filters to define
// a single record, from which options are drawn from.
export const recordProps = PropTypes.shape({
  adFormat: PropTypes.string.isRequired,
  assetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  assetType: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  market: PropTypes.string.isRequired,
  creativeAgency: PropTypes.string,
  excellentSpend: PropTypes.number,
  mediaAgency: PropTypes.string,
  partner: PropTypes.string,
  rank: PropTypes.string,
  score: PropTypes.number,
  spend: PropTypes.number,
});

// NOTE: Returns the previous month
export function initialDateRange() {
  const today = new Date();

  const year = today.getFullYear();
  const month = today.getMonth();

  return {
    endDate: dateToString(new Date(year, month, 0)),
    startDate: dateToString(new Date(year, month - 1, 1)),
    type: 'dynamic',
  };
}

export function mixpanelDateChange(dateRange, reportType) {
  track('custom_date_range_updated', {
    endDate: dateRange.endDate,
    reportType,
    startDate: dateRange.startDate,
  });
}
