import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectableTable, { propTypes as tableProps } from 'components/organisms/SelectableTable';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';
import { useViewPage } from 'utilities/mixpanel';

const propTypes = {
  addMixpanelData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  table: PropTypes.shape({
    headers: tableProps.headers,
    rows: tableProps.rows,
  }).isRequired,
  onFormAction: PropTypes.func.isRequired,
};

function BrandPageSelection({
  addMixpanelData,
  formData,
  table,
  onFormAction,
}) {
  useViewPage();
  const [selected, setSelected] = useState([]);

  const onAllSelect = () => {
    let updatedValues = [];
    if (selected.length === 0) {
      updatedValues = table.rows.map(({ data }) => data);
    }
    setSelected(updatedValues);
    const numPages = updatedValues.length;
    onFormAction(numPages > 0);
    addMixpanelData({ totalPages: numPages });
  };

  const onRowSelect = (data) => {
    const updatedValues = ArrayHelper.withOrWithoutObject(selected, data);
    setSelected([...updatedValues]);
    const numPages = updatedValues.length;
    onFormAction(numPages > 0);
    addMixpanelData({ totalPages: numPages });
  };

  return (
    <>
      <SelectableTable
        headers={table.headers}
        rows={table.rows}
        selected={selected}
        title="Available Brand Pages"
        selectAll
        onAllSelect={() => onAllSelect()}
        onRowSelect={(data) => onRowSelect(data)}
      />
      <form action={formData.url} id={formData.id} method="post">
        <AuthenticityTokenInput />
        {
          selected.map(({
            businessManagerApiId, name, value,
          }) => (
            <React.Fragment key={value}>
              <HiddenInput
                name="business_centers[][api_id]"
                value={businessManagerApiId}
              />
              <HiddenInput
                name="business_centers[]brand_pages[][api_id]"
                value={value}
              />
              <HiddenInput
                name="business_centers[]brand_pages[][name]"
                value={name}
              />
            </React.Fragment>
          ))
        }
      </form>
    </>
  );
}

BrandPageSelection.propTypes = propTypes;

export default BrandPageSelection;
