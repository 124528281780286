import React from 'react';
import PropTypes from 'prop-types';
import {
  getItemContent,
  itemProps,
} from 'utilities/item';
import styles from './ItemDetails.module.css';

const propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  item: itemProps.isRequired,
  columnCount: PropTypes.number,
};

const defaultProps = {
  columnCount: 3,
};

function renderDetail(key, label, item) {
  if (!item[key]) return null;

  return (
    <div key={key} className={styles.detail}>
      <div className="t-caption-1">{ label }</div>
      <h5>{ getItemContent(item, key) }</h5>
    </div>
  );
}

function ItemDetails({
  columnCount,
  details,
  item,
}) {
  return (
    <div>
      <div
        className={styles.details}
        style={{ gridTemplateColumns: `repeat(${columnCount}, auto)` }}
      >
        { details.map(({ key, label }) => renderDetail(key, label, item)) }
      </div>
    </div>
  );
}

ItemDetails.propTypes = propTypes;
ItemDetails.defaultProps = defaultProps;

export default ItemDetails;
