import { recordGroupKey } from 'components/reporting/dataFetcher';

export function groupRecords(records, dimensions, customDimensions, scoreVersionIds) {
  const groupKeys = [
    ...dimensions,
    ...scoreVersionIds.map((id) => `rank::${id}`),
    ...customDimensions.map((dimension) => `customSegment::${dimension}`),
  ];

  // NOTE: Grouping by keys in order to remove duplicate score data
  const grouped = records.reduce((obj, record) => {
    const key = recordGroupKey(record, groupKeys);

    // eslint-disable-next-line no-param-reassign
    obj[key] = obj[key] ? [...obj[key], record] : [record];

    return obj;
  }, {});

  return Object.values(grouped).map((group) => {
    const combined = group.reduce((bucketed, record) => ({
      ...bucketed,
      assetIds: [...bucketed.assetIds, ...record.assetIds],
      postIds: [...bucketed.postIds, ...record.postIds],
      spend: bucketed.spend + record.spend,
      checks: [...bucketed.checks, ...record.checks],
    }));

    // NOTE: We can't just count the unique passed checks because some could have float values
    // between 0 and 1, instead we need to sum the check values.
    const uniqueChecks = [...new Set(combined.checks)];
    const sumChecks = uniqueChecks.reduce((sum, check) => sum + parseFloat(check.split('::')[2]), 0);

    // NOTE: Removing duplicate asset and post ids to get accurate counts. Also removing
    // duplicate checks from having multiple queries by associated post id.
    return {
      ...combined,
      assetIds: [...new Set(combined.assetIds)],
      postCount: (new Set(combined.postIds)).size,
      passedChecks: sumChecks,
      totalChecks: uniqueChecks.length,
    };
  });
}
