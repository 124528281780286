import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';
import SelectableTable, { propTypes as tableProps } from 'components/organisms/SelectableTable';
import AuthenticityTokenInput from 'components/reusable/forms/AuthenticityTokenInput';
import { HiddenInput } from 'components/reusable/HiddenInput';
import ArrayHelper from 'components/utils/ArrayHelper';
import { track } from 'utilities/mixpanel';
import { nextAccountPageAccountSetupMetaAdsPath } from 'utilities/routes';

const propTypes = {
  addMixpanelData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFinalPage: PropTypes.bool.isRequired,
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      businessCenter: PropTypes.shape({
        apiId: PropTypes.string,
        name: PropTypes.string,
      }),
      rows: tableProps.rows,
    }),
  ).isRequired,
  onFormAction: PropTypes.func.isRequired,
};

class AdAccountSelection extends React.Component {
  constructor(props) {
    super(props);
    track('view_ad_account_selection');

    this.state = {
      tables: props.tables,
      isFinalPage: props.isFinalPage,
      allSelected: [],
      selected: [],
    };
  }

  onDataChange() {
    const { addMixpanelData, onFormAction } = this.props;
    const { selected } = this.state;
    const numAdAccounts = selected.length;
    onFormAction(numAdAccounts > 0);
    addMixpanelData({ totalAdAccounts: numAdAccounts });
  }

  onAllSelect(businessCenterApiId) {
    this.setState((prevState) => {
      const allSelected = ArrayHelper.withOrWithoutValue(
        prevState.allSelected,
        businessCenterApiId,
      );

      const tableValues = this.tableDataForBizCenter(businessCenterApiId);

      let updatedValues = prevState.selected;

      if (allSelected.includes(businessCenterApiId)) {
        // Add table rows to list of selected
        tableValues.forEach((row) => {
          const isSelected = ArrayHelper.exactObjectInArray(updatedValues, row);
          // Add if not there already
          if (!isSelected) {
            updatedValues.push(row);
          }
        });
      } else {
        // Remove table rows from list of selected
        tableValues.forEach((row) => {
          updatedValues = updatedValues.filter((elm) => (
            // Filter out ones that match
            !Object.keys(elm).every((key) => elm[key] === row[key])
          ));
        });
      }

      return {
        allSelected,
        selected: updatedValues,
      };
    }, () => this.onDataChange());
  }

  onRowSelect(businessCenterApiId, data) {
    this.setState((prevState) => {
      const updatedValues = ArrayHelper.withOrWithoutExactObjectFromArray(prevState.selected, data);

      // Check if all business center api ids are now selected
      const tableValues = this.tableDataForBizCenter(businessCenterApiId);
      const valuesForBizCenter = updatedValues.filter((elm) => (
        elm.businessCenterApiId === businessCenterApiId
      ));

      let { allSelected } = prevState;

      if (tableValues.length === valuesForBizCenter.length) {
        allSelected.push(businessCenterApiId);
      } else if (allSelected.includes(businessCenterApiId)) {
        allSelected = ArrayHelper.removeFromArray(allSelected, businessCenterApiId);
      }

      return {
        allSelected,
        selected: updatedValues,
      };
    }, () => this.onDataChange());
  }

  loadMore() {
    this.setState(async (prevState) => {
      let data;
      const { tables } = prevState;
      const url = nextAccountPageAccountSetupMetaAdsPath();

      try {
        data = await fetch(url).then((resp) => resp.json());
      } catch (error) {
        throw new Error(`Error loading more ad accounts: ${error}`);
      }

      const { contentData } = data;

      // Sorting rows after every load as requested by product
      const updatedRows = tables[0].rows.concat(...contentData.tables[0].rows);
      // Sort rows by sort function that compares the linked boolean of each row
      // Sorting function rearranges unlinked accounts to be at the beginning of the array
      // Based on true (1) - false (0) = 1 and false (0) - true (1) = -1
      const sortedRows = updatedRows.sort((x, y) => x.data.linked - y.data.linked);

      const updatedTables = [{
        ...tables[0],
        rows: sortedRows,
      }];

      this.setState({
        isFinalPage: contentData.isFinalPage,
        tables: updatedTables,
      });
    }, () => this.onDataChange());
  }

  tableDataForBizCenter(businessCenterApiId) {
    const { tables } = this.state;
    const { rows } = tables.find(({ businessCenter }) => (
      businessCenter.apiId === businessCenterApiId
    ));
    return rows.filter(({ data }) => !data.linked).map(({ data }) => data);
  }

  rowSelected(apiId) {
    const { selected } = this.state;
    return selected.filter((data) => data.businessCenterApiId === apiId);
  }

  renderLoadMoreButton(isFinalPage) {
    if (isFinalPage) return null;

    return (
      <div className="u-flexRow u-justifyCenter">
        <Button
          label="Load More"
          variant="tertiary"
          onClick={() => this.loadMore()}
        />
      </div>
    );
  }

  render() {
    const {
      formData: {
        id,
        url,
      },
      headers,
    } = this.props;

    const {
      isFinalPage, selected: stateSelected, tables,
    } = this.state;

    const emptyMessage = () => (
      <div className="tableEmptyMessage u-textAlignCenter">No available Ad Accounts</div>
    );

    const styledRows = (rows) => rows.map((row) => {
      if (row.data.linked) {
        return {
          ...row,
          columns: row.columns.map((column) => (<div className="disabledAdAccount">{ column }</div>)),
          disabledSelected: true,
        };
      }
      return row;
    });

    const tableSubtitle = (rows) => {
      if (rows.some(({ data }) => data.linked)) {
        return ('Pre-selected Ad Accounts have already been connected.');
      }

      return undefined;
    };

    return (
      <>
        {
          tables.map(({ businessCenter, rows }) => (
            <SelectableTable
              key={businessCenter.apiId}
              className="businessCenterTable"
              emptyMessage={emptyMessage()}
              headers={headers}
              rows={styledRows(rows)}
              selected={this.rowSelected(businessCenter.apiId)}
              subtitle={tableSubtitle(rows)}
              title={businessCenter.name}
              selectAll
              onAllSelect={() => this.onAllSelect(businessCenter.apiId)}
              onRowSelect={(rowData) => this.onRowSelect(businessCenter.apiId, rowData)}
            />
          ))
        }
        { this.renderLoadMoreButton(isFinalPage) }
        <form action={url} id={id} method="post">
          <AuthenticityTokenInput />
          {
            stateSelected.map(({
              businessCenterApiId, name, value,
            }) => (
              <React.Fragment key={`${businessCenterApiId}_${value}`}>
                <HiddenInput
                  name="business_centers[][api_id]"
                  value={businessCenterApiId}
                />
                <HiddenInput
                  name="business_centers[]ad_accounts[][api_id]"
                  value={value}
                />
                <HiddenInput
                  name="business_centers[]ad_accounts[][name]"
                  value={name}
                />
              </React.Fragment>
            ))
          }
        </form>
      </>
    );
  }
}

AdAccountSelection.propTypes = propTypes;

export default AdAccountSelection;
