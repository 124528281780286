import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Card,
  Icon,
  Search,
  Spinner,
} from '@makeably/creativex-design-system';
import SelectableItemsTable from 'components/molecules/SelectableItemsTable';
import { useViewPage } from 'utilities/mixpanel';
import {
  newSettingsLinkedPlatformAccountPath,
} from 'utilities/routes';
import styles from './LinkAccount.module.css';

const accountProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  linked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
});

const propTypes = {
  accounts: PropTypes.arrayOf(accountProps).isRequired,
  onContinue: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

const headers = [
  {
    key: 'name',
    label: 'Ad Account Name',
  },
  {
    key: 'id',
    label: 'Ad Account Id',
  },
];

function getItems(accounts) {
  return accounts.map((account) => ({
    disabled: { value: account.linked },
    name: { value: account.name },
    id: { value: account.id },
  }));
}

function SelectAccounts({
  accounts,
  onContinue,
  onLoadMore,
}) {
  const items = useMemo(() => getItems(accounts), [accounts]);
  const showPreCheckedCaption = useMemo(
    () => accounts.some((account) => account.linked), [accounts],
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [loadingItems, setLoadingItems] = useState(false);

  useViewPage('Select Accounts');

  useEffect(() => {
    if (loadingItems && items.length > 10) {
      setLoadingItems(false);
      setFilteredItems(items);
    }
  }, [loadingItems, items]);

  const handleSearch = (value) => {
    setSearch(value);

    const filtered = items.filter(
      (item) => item.name.value.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredItems(filtered);
  };

  const handleContinue = () => {
    const selectedIds = new Set(selectedItems.map((item) => item.id.value));
    const filteredAccounts = accounts.filter((account) => selectedIds.has(account.id));
    onContinue(filteredAccounts);
  };

  const handleLoadMore = () => {
    setLoadingItems(true);
    onLoadMore();
  };

  const captionAlignment = showPreCheckedCaption ? 'u-justifyBetween' : 'u-justifyEnd';

  return (
    <div className="u-flexColumn u-gap-24">
      <h5>
        Select the Ad Accounts you want to give CreativeX Read Only access to.
      </h5>
      <h5 className={styles.iconOverride}>
        <Icon name="infoCircle" noWrapper />
        { ' ' }
        Note that Parent and Child Ad Accounts are both displayed below without
        differing labels due to Pinterest&apos;s current API capabilities.
        Please don&apos;t connect Ad Accounts you know to be Parent Ad Accounts.
      </h5>
      <Divider />
      <div>
        <div className={`u-flexRow u-marginBottom-8 ${captionAlignment}`}>
          { showPreCheckedCaption && (
            <div className="t-body-2">Pre-checked ad accounts have already been connected</div>
          ) }
          <div className="t-body-2">{ `Ad Accounts Selected: ${selectedItems.length}` }</div>
        </div>
        <Card className="u-flexColumn u-gap-16">
          <div className="u-flexRow u-justifyEnd">
            <Search
              placeholder="Search Ad Accounts"
              value={search}
              onChange={handleSearch}
            />
          </div>
          <SelectableItemsTable
            className={styles.table}
            headers={headers}
            items={filteredItems}
            selectedItems={selectedItems}
            checkDisabledItems
            onItemsSelect={setSelectedItems}
          />
          { items.length === 10 && !loadingItems && (
          <div className="u-flexRow u-justifyCenter">
            <Button
              label="Load More"
              variant="tertiary"
              onClick={handleLoadMore}
            />
          </div>
          ) }
          { loadingItems && (
          <div className="u-flexRow u-justifyCenter">
            <Spinner />
          </div>
          ) }
        </Card>
      </div>
      <div className="u-flexRow u-justifyEnd">
        <div className="u-buttonGroup">
          <Button
            label="Cancel"
            url={newSettingsLinkedPlatformAccountPath()}
            variant="secondary"
          />
          <Button
            disabled={selectedItems.length === 0}
            label="Continue"
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  );
}

SelectAccounts.propTypes = propTypes;

export default SelectAccounts;
