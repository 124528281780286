import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import Notifier from 'components/account_setup/shared_steps/Notifier';
import {
  useViewPage,
} from 'utilities/mixpanel';
import {
  settingsFacebookAdPagesPath,
  settingsLinkedPlatformAccountsPath,
} from 'utilities/routes';
import styles from './LapsedAccountConfirmation.module.css';

const AD_ACCOUNT_COPY = {
  accounts: {
    title: 'Ad Accounts Connected',
    subtitle: {
      multiple: 'The following (#) Ad Accounts have been reconnected:',
      single: 'The following Ad Account has been reconnected:',
    },
    titleIconColor: 'green',
    titleIconName: 'checkCircle',
  },
  erroredAccounts: {
    title: 'Ad Account Connection Failed',
    subtitle: {
      multiple: 'The following (#) Ad Accounts failed to reconnect:',
      single: 'The following Ad Account failed to reconnect:',
    },
    titleIconColor: 'red',
    titleIconName: 'xCircle',
  },
  erroredBusinessManagers: {
    title: 'Business Manager Connection Failed',
    subtitle: {
      multiple: 'The following (#) Business Managers failed to reconnect:',
      single: 'The following Business Manager failed to reconnect:',
    },
    titleIconColor: 'red',
    titleIconName: 'xCircle',
  },
};

const BRAND_PAGE_COPY = {
  accounts: {
    title: 'Brand Pages Connected',
    subtitle: {
      multiple: 'The following (#) Brand Pages have been reconnected:',
      single: 'The following Brand Page has been reconnected:',
    },
    titleIconColor: 'green',
    titleIconName: 'checkCircle',
  },
  erroredAccounts: {
    title: 'Brand Page Connection Failed',
    subtitle: {
      multiple: 'The following (#) Brand Pages failed to reconnect:',
      single: 'The following Brand Page failed to reconnect:',
    },
    titleIconColor: 'red',
    titleIconName: 'xCircle',
    tooltip: 'Brand Page connection failures may occur due to insufficient permissions, '
      + 'incorrect ownership, or other reasons. '
      + 'Contact the admin of your Facebook Business Manager for help.',
  },
};

const accountProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const propTypes = {
  accounts: PropTypes.arrayOf(accountProps).isRequired,
  erroredAccounts: PropTypes.arrayOf(accountProps).isRequired,
  brandPages: PropTypes.bool,
  erroredBusinessManagers: PropTypes.arrayOf(accountProps),
};

const defaultProps = {
  brandPages: false,
  erroredBusinessManagers: [],
};

function renderRows(items) {
  return (
    <div className={`t-body-1 ${styles.accounts}`}>
      {
        items.map(({ id, name }) => (
          <div key={`${name}_${id}`} className="u-flexRow u-flexAlignCenter">
            <div className={styles.dot} />
            { `${name} (${id})` }
          </div>
        ))
      }
    </div>
  );
}

function renderIcon(color, name) {
  return <Icon color={color} name={name} />;
}

function getButtonProps(dataSize, index, setIndex, brandPages) {
  const isLastItem = (index + 1) === dataSize;
  const url = brandPages ? settingsFacebookAdPagesPath() : settingsLinkedPlatformAccountsPath();
  const pageForward = () => setIndex(index + 1);

  return {
    action: isLastItem ? 'link' : 'callback',
    actionItem: isLastItem ? url : pageForward,
    buttonText: isLastItem ? 'Done' : 'Continue',
  };
}

function getBackButtonProps(index, setIndex) {
  if (index > 0) {
    return {
      action: 'callback',
      actionItem: () => setIndex(index - 1),
      buttonText: 'Back',
    };
  }

  return undefined;
}

function renderNotifier(accountTypes, index, setIndex, brandPages) {
  const {
    data,
    key,
  } = accountTypes[index];
  const copy = brandPages ? BRAND_PAGE_COPY[key] : AD_ACCOUNT_COPY[key];

  let subtitle;
  if (data.length > 1) {
    subtitle = copy.subtitle.multiple.replace('#', data.length);
  } else {
    subtitle = copy.subtitle.single;
  }

  return (
    <Notifier
      backButtonProps={getBackButtonProps(index, setIndex)}
      body={renderRows(data)}
      buttonProps={getButtonProps(accountTypes.length, index, setIndex, brandPages)}
      subtitle={subtitle}
      title={copy.title}
      titleIcon={renderIcon(copy.titleIconColor, copy.titleIconName)}
      tooltip={copy.tooltip}
    />
  );
}

function LapsedAccountConfirmation({
  accounts,
  brandPages,
  erroredAccounts,
  erroredBusinessManagers,
}) {
  useViewPage();
  const allAccountTypes = [
    {
      key: 'accounts',
      data: accounts,
    },
    {
      key: 'erroredAccounts',
      data: erroredAccounts,
    },
    {
      key: 'erroredBusinessManagers',
      data: erroredBusinessManagers,
    },
  ];
  const filteredAccountTypes = allAccountTypes.filter((type) => type.data.length > 0);
  const [index, setIndex] = useState(0);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        { renderNotifier(filteredAccountTypes, index, setIndex, brandPages) }
      </div>
    </div>
  );
}

LapsedAccountConfirmation.propTypes = propTypes;
LapsedAccountConfirmation.defaultProps = defaultProps;

export default LapsedAccountConfirmation;
