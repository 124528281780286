import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from '@makeably/creativex-design-system';
import { propTypes as postInfoPropTypes } from 'components/admin/review_queue/post_info/PostInfo';
import ChallengeControls from 'components/internal/review/ChallengeControls';
import ChallengeDisplay from 'components/internal/review/ChallengeDisplay';
import {
  checkProps,
  isChallengeDisabled,
  patchChallengeUpdate,
  postChallengeEscalate,
} from 'components/internal/review/shared';

const propTypes = {
  canEvaluate: PropTypes.bool.isRequired,
  canEvaluateEscalated: PropTypes.bool.isRequired,
  challenge: PropTypes.shape({
    changeJustification: PropTypes.string.isRequired,
    check: checkProps.isRequired,
    id: PropTypes.number.isRequired,
    noChangeJustification: PropTypes.string.isRequired,
    postInfo: PropTypes.shape(postInfoPropTypes).isRequired,
    reviewStartAt: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
};

function Challenge({
  canEvaluate,
  canEvaluateEscalated,
  challenge,
}) {
  const {
    changeJustification,
    check,
    id,
    noChangeJustification,
    reviewStartAt,
    state,
  } = challenge;

  const initialPassed = check.passed;
  const [isBusy, setIsBusy] = useState(false);
  const [isEscalating, setIsEscalating] = useState(false);
  const [justification, setJustification] = useState(noChangeJustification);
  const [justificationEdited, setJustificationEdited] = useState(false);
  const [passed, setPassed] = useState(initialPassed);
  const challengeDisabled = isChallengeDisabled(challenge, canEvaluate, canEvaluateEscalated);
  const isDisabled = challengeDisabled || isBusy;

  useEffect(() => {
    const changed = passed !== initialPassed;
    if (!justificationEdited) {
      setJustification(changed ? changeJustification : noChangeJustification);
    }
  }, [passed, initialPassed, changeJustification, noChangeJustification]);

  const handleJustificationChange = (value) => {
    setJustificationEdited(true);
    setJustification(value);
  };

  const handleEscalate = async () => {
    setIsEscalating(true);
    setIsBusy(true);
    await postChallengeEscalate(id);
    window.location.reload();
  };

  const handleEvaluate = async () => {
    setIsEscalating(false);
    setIsBusy(true);
    await patchChallengeUpdate(id, passed, justification, reviewStartAt);
    window.location.reload();
  };

  return (
    <Card>
      <div className="u-scrollShadowRight">
        <ChallengeDisplay
          challenge={challenge}
          isDisabled={isDisabled}
          passed={passed}
          onPassedChange={setPassed}
        />
        <div className="u-marginTop-24">
          <ChallengeControls
            isBusy={isBusy}
            isDisabled={isDisabled}
            isEscalated={state === 'escalated'}
            isEscalating={isEscalating}
            isPending={state === 'pending'}
            justification={justification}
            onEscalate={handleEscalate}
            onEvaluate={handleEvaluate}
            onJustificationChange={handleJustificationChange}
          />
        </div>
      </div>
    </Card>
  );
}

Challenge.propTypes = propTypes;

export default Challenge;
