import {
  dateToString,
  stringToDate,
} from '@makeably/creativex-design-system';
import { get } from 'utilities/requests';

const HALF_YEAR_MONTH_INDEXES = [0, 6];
const QUARTER_MONTH_INDEXES = [0, 3, 6, 9];

export function bucketDates({ endDate, startDate }) {
  const buckets = [];
  let currentDate = stringToDate(startDate);
  const end = stringToDate(endDate);

  while (currentDate <= end) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();

    let bucketEnd;
    let bucketType;

    // NOTE: Partial month cases are handled by daily summaries
    if (day !== 1 && new Date(year, month + 1, 0) <= end) {
      bucketEnd = new Date(year, month + 1, 0);
      bucketType = 'daily';
    } else if (new Date(year, month + 1, 0) > end) {
      bucketEnd = new Date(end);
      bucketType = 'daily';
    // NOTE: Full year/half/quarter/month cases are handled by audit post summaries
    } else if (month === 0 && new Date(year + 1, 0, 0) <= end) {
      bucketEnd = new Date(year + 1, 0, 0);
      bucketType = 'year';
    } else if (HALF_YEAR_MONTH_INDEXES.includes(month) && new Date(year, month + 6, 0) <= end) {
      bucketEnd = new Date(year, month + 6, 0);
      bucketType = 'half';
    } else if (QUARTER_MONTH_INDEXES.includes(month) && new Date(year, month + 3, 0) <= end) {
      bucketEnd = new Date(year, month + 3, 0);
      bucketType = 'quarter';
    } else if (new Date(year, month + 1, 0) <= end) {
      bucketEnd = new Date(year, month + 1, 0);
      bucketType = 'month';
    }

    buckets.push({
      endDate: dateToString(bucketEnd),
      startDate: dateToString(currentDate),
      type: bucketType,
    });
    currentDate = new Date(bucketEnd);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return buckets;
}

export async function fetchBucket(bucket, url, timePeriod = null) {
  const params = {
    end_date: bucket.endDate,
    start_date: bucket.startDate,
    date_type: 'dynamic',
    bucket_type: bucket.type,
    time_period: timePeriod?.value,
    type: 'inflight',
  };

  return get(url(params));
}

export function recordGroupKey(record, groupByKeys) {
  return groupByKeys.map((key) => record[key]).join('::');
}

// Split date range into buckets, fetch data for each bucket, and combine the data before returning.
export async function getCustomData(range, url, groupingUrl, groupFunction, timePeriod) {
  const bucketedDates = bucketDates(range);

  const responses = await Promise.all(
    bucketedDates.map((bucket) => (
      fetchBucket(bucket, url, timePeriod))),
  );

  if (responses.some((resp) => resp.isError)) {
    const hasTimeout = responses.some((resp) => resp.status === 504);

    return { error: hasTimeout ? 'The data request has timed out' : 'The data could not be loaded' };
  }

  const response = await get(groupingUrl());

  const allRecords = responses.reduce((all, { data }) => [...all, ...data], []);

  if (response.isError) {
    return { error: 'The data could not be loaded' };
  }

  const {
    data: {
      customDimensions,
      dimensions,
      scoreVersionIds,
    },
  } = response;

  const grouped = groupFunction(allRecords, dimensions, customDimensions, scoreVersionIds);

  return { data: grouped };
}
