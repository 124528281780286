import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/account_setup/shared_steps/AdAccountForm';
import AdAccountAssignmentSection from 'components/account_setup/tiktok_ads/steps/AdAccountAssignmentSection';
import ObjectHelper from 'components/utils/ObjectHelper';
import {
  track,
} from 'utilities/mixpanel';

const propTypes = {
  brandOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.node),
  ).isRequired,
  businessCenters: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      adAccounts: PropTypes.arrayOf(PropTypes.object),
      info: PropTypes.shape({
        apiId: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  marketOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  partnerOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.node),
  ).isRequired,
  onFormAction: PropTypes.func.isRequired,
};

class AdAccountAssignment extends React.Component {
  constructor(props) {
    super(props);
    track('view_ad_account_assignment');
    const {
      brandOptions,
      businessCenters,
      marketOptions,
      partnerOptions,
    } = props;

    this.selectors = [
      {
        label: 'Brand',
        name: 'brand',
        options: ObjectHelper.arraysToObjects(brandOptions),
      },
      {
        label: 'Market',
        name: 'market',
        options: ObjectHelper.valuesToObjects(marketOptions),
      },
      {
        label: 'Partner',
        name: 'partner',
        options: ObjectHelper.arraysToObjects(partnerOptions),
      },
    ];

    const formAdAccounts = [];
    businessCenters.forEach((businessCenter) => (
      businessCenter.adAccounts.forEach((propAdAccount) => {
        const adAccount = propAdAccount;

        adAccount.brand = undefined;
        adAccount.market = undefined;
        adAccount.partner = undefined;
        adAccount.complete = false;

        formAdAccounts.push(adAccount);
      })
    ));

    this.state = { formAdAccounts };
  }

  onDataChange() {
    const { onFormAction } = this.props;
    const { formAdAccounts } = this.state;

    onFormAction(formAdAccounts.every((adAct) => adAct.complete));
  }

  onOptionSelect(option, name, adAccountApiId, bizCenterApiId) {
    this.setState((prevState) => {
      const { formAdAccounts } = prevState;
      const adAccount = formAdAccounts.find((adAct) => (
        adAct.apiId === adAccountApiId && adAct.businessCenterApiId === bizCenterApiId
      ));

      adAccount[name] = option;
      adAccount.complete = [
        adAccount.brand,
        adAccount.market,
        adAccount.partner,
      ].every((prop) => prop !== undefined);

      return { formAdAccounts };
    }, () => this.onDataChange());
  }

  render() {
    const {
      businessCenters,
      formData,
    } = this.props;

    const { formAdAccounts } = this.state;

    return (
      <div className="adAccountAssignment">
        {
          businessCenters.map(({ info, adAccounts }) => {
            const formAdAccountsForBizCenter = formAdAccounts.filter((adAct) => (
              adAct.businessCenterApiId === info.apiId
            ));

            const completedAdAccounts = formAdAccountsForBizCenter.filter((adAct) => (
              adAct.complete
            ));

            return (
              <div key={info.apiId} className="adAccountAssignmentGroup">
                <div className="u-flexRow u-justifySpaceBetween u-flexAlignCenter">
                  <h5 className="tableTitle">{ info.name }</h5>
                  <div className="tableSecondary">
                    Ad Accounts Associated:
                    { ' ' }
                    { completedAdAccounts.length }
                    { ' ' }
                    of
                    { ' ' }
                    { adAccounts.length }
                  </div>
                </div>
                {
                  formAdAccountsForBizCenter.map((adAccount) => {
                    const onSelect = (option, name) => (
                      this.onOptionSelect(
                        option,
                        name,
                        adAccount.apiId,
                        adAccount.businessCenterApiId,
                      )
                    );

                    return (
                      <AdAccountAssignmentSection
                        key={adAccount.apiId}
                        adAccount={adAccount}
                        selectors={this.selectors}
                        onSelect={onSelect}
                      />
                    );
                  })
                }
              </div>
            );
          })
        }
        <Form
          adAccounts={formAdAccounts.filter((adAct) => adAct.complete)}
          formData={formData}
        />
      </div>
    );
  }
}

AdAccountAssignment.propTypes = propTypes;

export default AdAccountAssignment;
