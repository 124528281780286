import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stepper,
} from '@makeably/creativex-design-system';
import {
  useViewPage,
} from 'utilities/mixpanel';
import { get } from 'utilities/requests';
import { allAdAccountsAccountSetupPinterestIndexPath } from 'utilities/routes';
import AssociateAccounts, { dropdownProps } from './AssociateAccounts';
import Confirmation from './Confirmation';
import styles from './LinkAccount.module.css';
import SelectAccounts from './SelectAccounts';

const accountProps = PropTypes.shape({
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  linked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
});

const propTypes = {
  accounts: PropTypes.arrayOf(accountProps).isRequired,
  formUrl: PropTypes.string.isRequired,
  ...dropdownProps,
};

const steps = [
  {
    step: 1,
    label: 'Authorize Pinterest',
  },
  {
    step: 2,
    label: 'Select Accounts',
  },
  {
    step: 3,
    label: 'Associate Accounts',
  },
  {
    step: 4,
    label: 'Confirmation',
  },
];

function LinkAccount({
  accounts,
  brands,
  formUrl,
  markets,
  partners,
}) {
  useViewPage();
  const [currentStep, setCurrentStep] = useState(2);
  const [fetchedAccounts, setFetchedAccounts] = useState(accounts);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [accountsWithAssociations, setAccountsWithAssociations] = useState([]);

  const handleLoadMore = async () => {
    const response = await get(allAdAccountsAccountSetupPinterestIndexPath());
    setFetchedAccounts(response.data.accounts);
  };

  const handleAccountSelection = (selections) => {
    setSelectedAccounts(selections);
    setCurrentStep(3);
  };

  const handleAccountAssociations = (associations) => {
    setAccountsWithAssociations(selectedAccounts.map((account) => ({
      ...account,
      ...associations.get(account.id),
    })));
    setCurrentStep(4);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 2:
        return (
          <SelectAccounts
            accounts={fetchedAccounts}
            onContinue={handleAccountSelection}
            onLoadMore={handleLoadMore}
          />
        );
      case 3:
        return (
          <AssociateAccounts
            brands={brands}
            markets={markets}
            partners={partners}
            selectedAccounts={selectedAccounts}
            onContinue={handleAccountAssociations}
            onGoBack={() => setCurrentStep(2)}
          />
        );
      case 4:
        return (
          <Confirmation
            accountsWithAssociations={accountsWithAssociations}
            formUrl={formUrl}
            onGoBack={() => setCurrentStep(3)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.stepper}>
        <Stepper currentStep={currentStep} steps={steps} />
      </div>
      { renderStep() }
    </div>
  );
}

LinkAccount.propTypes = propTypes;

export default LinkAccount;
