import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { emptyState } from 'components/internal/shared';
import ItemsTable from 'components/molecules/ItemsTable';

const propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'description',
    label: 'Description',
  },
];

function getItem({
  description,
  id,
  path,
  type,
}) {
  return {
    description: { value: description ?? '' },
    id: {
      element: <a href={path}>{ id }</a>,
      value: `${type}::${id}`,
    },
    type: {
      element: <a href={path}>{ type }</a>,
      value: type,
    },
  };
}

function Results({ records }) {
  const items = useMemo(() => records.map((record) => getItem(record)), [records]);

  if (records.length === 0) return emptyState;

  return (
    <div className="u-flexRow u-scrollShadowRight">
      <ItemsTable
        headers={headers}
        items={items}
      />
    </div>
  );
}

Results.propTypes = propTypes;

export default Results;
