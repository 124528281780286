import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CopyButton,
  Icon,
} from '@makeably/creativex-design-system';
import { checkProps } from 'components/internal/review/shared';
import styles from './CheckGuideline.module.css';

const propTypes = {
  check: checkProps,
  isDisabled: PropTypes.bool,
  needsValidation: PropTypes.bool,
  passed: PropTypes.bool,
  onNeedsValidationChange: PropTypes.func,
  onPassedChange: PropTypes.func,
};

const defaultProps = {
  check: {},
  isDisabled: undefined,
  needsValidation: undefined,
  onNeedsValidationChange: undefined,
  onPassedChange: undefined,
  passed: undefined,
};

function renderIcon(passed) {
  switch (passed) {
    case true:
      return <Icon color="green" name="checkCircle" noWrapper />;
    case false:
      return <Icon color="red" name="xCircle" noWrapper />;
    default:
      return <Icon color="orange" name="questionCircle" noWrapper />;
  }
}

function CheckGuideline({
  check,
  isDisabled,
  needsValidation,
  onNeedsValidationChange,
  onPassedChange,
  passed,
}) {
  const {
    definition,
    description,
    rule,
  } = check;

  const handleClick = () => {
    if (needsValidation) {
      onNeedsValidationChange?.(false);
    } else {
      onPassedChange?.(!passed);
    }
  };

  const buttonClasses = classNames(
    styles.button,
    {
      [styles.needsValidation]: needsValidation,
      [styles.disabled]: isDisabled,
    },
  );

  return (
    <div className={styles.main}>
      <button
        className={buttonClasses}
        disabled={isDisabled}
        type="button"
        onClick={handleClick}
      >
        { renderIcon(passed) }
      </button>
      <h4>{ rule }</h4>
      <div />
      <div>
        <h5 className={styles.definition}>
          { definition }
        </h5>
        <div className="u-flexRow u-gap-8">
          <div>{ description }</div>
          <CopyButton text={description} />
        </div>
      </div>
    </div>
  );
}

CheckGuideline.propTypes = propTypes;
CheckGuideline.defaultProps = defaultProps;

export default CheckGuideline;
