import React from 'react';
import PropTypes from 'prop-types';
import AdAccountAssignmentSection from 'components/account_setup/meta_ads/steps/AdAccountAssignmentSection';
import AdAccountBulkAssociate from 'components/account_setup/meta_ads/steps/AdAccountBulkAssociate';
import Form from 'components/account_setup/shared_steps/AdAccountForm';
import ObjectHelper from 'components/utils/ObjectHelper';
import { track } from 'utilities/mixpanel';

const propTypes = {
  brandOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.node),
  ).isRequired,
  businessCenters: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      adAccounts: PropTypes.arrayOf(PropTypes.object),
      info: PropTypes.shape({
        apiId: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  marketOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  partnerOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.node),
  ).isRequired,
  onFormAction: PropTypes.func.isRequired,
};

class AdAccountAssignment extends React.Component {
  constructor(props) {
    super(props);
    track('view_ad_account_assignment');

    const {
      brandOptions,
      businessCenters,
      marketOptions,
      partnerOptions,
    } = props;

    this.selectors = [
      {
        label: 'Brand',
        name: 'brand',
        options: ObjectHelper.arraysToObjects(brandOptions),
      },
      {
        label: 'Market',
        name: 'market',
        options: ObjectHelper.valuesToObjects(marketOptions),
      },
      {
        label: 'Partner',
        name: 'partner',
        options: ObjectHelper.arraysToObjects(partnerOptions),
      },
    ];

    const formAdAccounts = [];
    businessCenters.forEach((businessCenter) => (
      businessCenter.adAccounts.forEach((propAdAccount) => {
        const adAccount = propAdAccount;

        adAccount.brand = undefined;
        adAccount.market = undefined;
        adAccount.partner = undefined;
        adAccount.complete = false;
        adAccount.checked = false;

        formAdAccounts.push(adAccount);
      })
    ));

    this.state = { formAdAccounts };
  }

  onDataChange() {
    const { onFormAction } = this.props;
    const { formAdAccounts } = this.state;

    onFormAction(formAdAccounts.every((adAct) => adAct.complete));
  }

  onOptionSelect(option, name, adAccountApiId, bizCenterApiId) {
    this.setState((prevState) => {
      const { formAdAccounts } = prevState;
      const adAccount = formAdAccounts.find((adAct) => (
        adAct.apiId === adAccountApiId && adAct.businessCenterApiId === bizCenterApiId
      ));

      adAccount[name] = option;
      adAccount.complete = [
        adAccount.brand,
        adAccount.market,
        adAccount.partner,
      ].every((prop) => prop !== undefined);

      return { formAdAccounts };
    }, () => this.onDataChange());
  }

  onApplyOptions(brand, market, partner) {
    this.setState((prevState) => {
      const { formAdAccounts } = prevState;
      const newFormAdAccounts = formAdAccounts.map((adAccount) => {
        const adAccountCopy = adAccount;
        if (adAccount.checked) {
          if (brand.value !== undefined) {
            adAccountCopy.brand = brand;
          }
          if (market.value !== undefined) {
            adAccountCopy.market = market;
          }
          if (partner.value !== undefined) {
            adAccountCopy.partner = partner;
          }
          adAccountCopy.complete = [
            adAccount.brand,
            adAccount.market,
            adAccount.partner,
          ].every((prop) => prop !== undefined);
        }
        return adAccountCopy;
      });

      return { formAdAccounts: newFormAdAccounts };
    }, () => this.onDataChange());
  }

  onAdAccountChecked(checked, adAccountApiId, bizCenterApiId) {
    this.setState((prevState) => {
      const { formAdAccounts } = prevState;
      const adAccount = formAdAccounts.find((adAct) => (
        adAct.apiId === adAccountApiId && adAct.businessCenterApiId === bizCenterApiId
      ));
      adAccount.checked = checked;

      return { formAdAccounts };
    });
  }

  onSelectAll(isChecked) {
    this.setState((prevState) => {
      const { formAdAccounts } = prevState;
      const newFormAdAccounts = formAdAccounts.map((adAccount) => {
        const adAccountCopy = adAccount;
        adAccountCopy.checked = isChecked;
        return adAccountCopy;
      });

      return { formAdAccounts: newFormAdAccounts };
    });
  }

  render() {
    const {
      businessCenters,
      formData,
    } = this.props;

    const { formAdAccounts } = this.state;

    const allSelected = formAdAccounts.every((adAct) => adAct.checked);

    const counts = {
      totalCount: 0,
      selectedCount: 0,
    };
    formAdAccounts.forEach((adAccount) => {
      counts.totalCount += 1;
      if (adAccount.checked) {
        counts.selectedCount += 1;
      }
    });

    const onAdAccountChecked = (checked, adAccountApiId, bizCenterApiId) => {
      this.onAdAccountChecked(checked, adAccountApiId, bizCenterApiId);
    };

    const onOptionSelect = (option, name, adAccountApiId, bizCenterApiId) => {
      this.onOptionSelect(option, name, adAccountApiId, bizCenterApiId);
    };

    return (
      <div className="adAccountAssignment">
        <div className="adAccountAssignment-bulkContainer sticky">
          <AdAccountBulkAssociate
            selectAllChecked={allSelected}
            selectedCount={counts.selectedCount}
            selectors={this.selectors}
            totalCount={counts.totalCount}
            onApply={(brand, market, partner) => this.onApplyOptions(brand, market, partner)}
            onSelect={(isChecked) => this.onSelectAll(isChecked)}
          />
        </div>
        <div>
          {
            businessCenters.map(({ info, adAccounts }) => (
              <AdAccountAssignmentSection
                key={info.apiId}
                adAccounts={adAccounts}
                info={info}
                selectors={this.selectors}
                onAdAccountChecked={onAdAccountChecked}
                onOptionSelect={onOptionSelect}
              />
            ))
          }
        </div>
        <Form
          adAccounts={formAdAccounts.filter((adAct) => adAct.complete)}
          formData={formData}
        />
      </div>
    );
  }
}

AdAccountAssignment.propTypes = propTypes;

export default AdAccountAssignment;
