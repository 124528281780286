import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  Divider,
  MoreButton,
} from '@makeably/creativex-design-system';
import SegmentModal from 'components/custom_segments/SegmentModal';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { getItemSortBy } from 'utilities/item';
import { setItemElement } from 'utilities/itemElement';
import { track } from 'utilities/mixpanel';
import {
  destroy,
  patch,
  post,
} from 'utilities/requests';
import {
  taxonomySegmentOptionCodesPath,
  taxonomySegmentOptionPath,
  taxonomySegmentOptionsPath,
  taxonomySegmentOptionValuesPath,
} from 'utilities/routes';
import styles from './CustomSegments.module.css';

const propTypes = {
  customSegments: PropTypes.arrayOf(PropTypes.shape({
    codesCount: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    valuesCount: PropTypes.number.isRequired,
  })).isRequired,
};

const headers = [
  {
    key: 'name',
    label: 'Segment Names',
  },
  {
    key: 'valuesCount',
    label: 'Value Count',
  },
  {
    key: 'codesCount',
    label: 'Code Count',
  },
  {
    key: 'actions',
    label: '',
    sortable: false,
  },
];

async function saveOrUpdateSegment(name, id = null) {
  const formData = new FormData();
  formData.append('name', name);

  let response;
  if (id) {
    response = await patch(taxonomySegmentOptionPath(id), formData);
  } else {
    response = await post(taxonomySegmentOptionsPath(), formData);
  }

  return response.data;
}

async function deleteSegment(segmentId) {
  const response = await destroy(taxonomySegmentOptionPath(segmentId));
  return response.data;
}

function getActions(handleEdit, segment, handleDelete) {
  return (
    <MoreButton
      menuSize="small"
      options={[{
        label: 'Edit',
        onClick: () => handleEdit(segment),
      },
      {
        label: 'Delete',
        onClick: () => handleDelete(segment.id),
      }]}
    />
  );
}

function getItems(customSegments, handleEdit, handleDelete) {
  return customSegments.map((segment) => setItemElement({
    actions: { element: getActions(handleEdit, segment, handleDelete) },
    codesCount: {
      display: {
        button: () => { window.location.href = taxonomySegmentOptionCodesPath(segment.id); },
        disabled: segment.valuesCount === 0,
      },
      value: segment.codesCount,
    },
    id: { value: segment.id },
    name: { value: segment.name },
    valuesCount: {
      display: {
        button: () => {
          window.location.href = taxonomySegmentOptionValuesPath(segment.id);
        },
      },
      value: segment.valuesCount,
    },
  }));
}

function CustomSegments({
  customSegments,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSegment, setEditSegment] = useState({});
  const [sort, setSort] = useState();

  track('view_custom_segments');

  const handleEditSegment = (segment) => {
    setEditSegment(segment);
    setIsModalOpen(true);
  };

  const handleDeleteSegment = async (segmentId) => {
    const response = await deleteSegment(segmentId);
    addToast(response.message, { type: response.success ? 'success' : 'error' });
    window.location.reload();
  };

  const handleSegmentSaveOrUpdate = async (name) => {
    const response = await saveOrUpdateSegment(name, editSegment.id);

    addToast(response.message, { type: response.success ? 'success' : 'error' });
    window.location.reload();
  };

  const handleSegmentCancel = () => {
    setIsModalOpen(false);
    setEditSegment({});
  };

  const items = useMemo(
    () => getItems(customSegments, handleEditSegment, handleDeleteSegment),
    [customSegments],
  );
  const sortedItems = useMemo(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      return items.slice().sort(byKeyDir);
    }
    return items;
  }, [items, sort]);

  return (
    <>
      <Card padding>
        <div className="u-flexColumn u-gap-24">
          <div className="u-flexRow u-justifyBetween u-alignCenter">
            <h4>Custom Segments</h4>
            <AddNewButton
              label="New Custom Segment"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
          <Divider />
          <ItemsTable
            className={styles.table}
            headers={headers}
            items={sortedItems}
            sort={sort}
            onSortChange={setSort}
          />
          { customSegments.length === 0 && (
            <div className="u-textCenter u-marginTop-16">
              No available custom segments.
            </div>
          ) }
        </div>
      </Card>
      <SegmentModal
        existingValue={editSegment.name}
        isOpen={isModalOpen}
        type="segment"
        onCancel={handleSegmentCancel}
        onSave={handleSegmentSaveOrUpdate}
      />
    </>
  );
}

CustomSegments.propTypes = propTypes;

export default CustomSegments;
