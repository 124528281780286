import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Widget,
} from '@duik/it';
import {
  Portal,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Checkbox,
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';
import Select from 'components/atoms/Select';
import { track } from 'utilities/mixpanel';

const propTypes = {
  selectAllChecked: PropTypes.bool.isRequired,
  selectedCount: PropTypes.number.isRequired,
  selectors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      options: PropTypes.object,
    }),
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  onApply: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

class AdAccountBulkAssociate extends React.Component {
  constructor(props) {
    super(props);
    track('view_ad_account_bulk_associate');

    const {
      selectors,
    } = props;

    const activeSelectorOptions = {};
    selectors.forEach((selector) => {
      activeSelectorOptions[selector.name] = { value: undefined };
    });

    this.state = {
      activeSelectorOptions,
      alertOpen: false,
      collapsed: false,
      message: undefined,
      severity: undefined,
    };
  }

  handleCollapsableToggle() {
    this.setState((prevState) => {
      const { collapsed } = prevState;

      return { collapsed: !collapsed };
    });
  }

  onSelectAll() {
    const {
      selectAllChecked,
      totalCount,
      onSelect,
    } = this.props;

    const newSelectAllChecked = !selectAllChecked;

    onSelect(newSelectAllChecked);

    if (newSelectAllChecked) {
      this.setState({
        alertOpen: true,
        message: `${totalCount} Ad Account(s) Selected`,
        severity: 'info',
      });
    }
  }

  onOptionSelect(option, selectorName) {
    this.setState((prevState) => {
      const { activeSelectorOptions } = prevState;

      activeSelectorOptions[selectorName] = option;

      return { activeSelectorOptions };
    });
  }

  applySnackBarMessage(brand, market, partner) {
    const {
      selectedCount,
    } = this.props;

    const assigned = [];
    if (brand.value !== undefined) {
      assigned.push(`Brand: ${brand.label}`);
    }
    if (market.value !== undefined) {
      assigned.push(`Market: ${market.label}`);
    }
    if (partner.value !== undefined) {
      assigned.push(`Partner: ${partner.label}`);
    }
    return (`You have assigned ${assigned.join('; ')} to ${selectedCount} ad account(s)`);
  }

  renderSelectors() {
    const {
      selectors,
    } = this.props;

    const {
      activeSelectorOptions,
    } = this.state;

    return (
      selectors.map((selector, idx) => {
        const menuPosition = idx === (selectors.length - 1) ? 'bottom-left' : 'bottom-right';

        const onSelect = (option) => {
          this.onOptionSelect(
            option,
            selector.name,
          );
        };

        return (
          <div key={selector.label}>
            <Select
              {...selector} // eslint-disable-line react/jsx-props-no-spreading
              activeOption={activeSelectorOptions[selector.name]}
              menuPosition={menuPosition}
              block
              searchable
              onOptionClick={onSelect}
            />
          </div>
        );
      })
    );
  }

  render() {
    const {
      selectAllChecked,
      selectedCount,
      selectors,
      totalCount,
      onApply,
      onSelect,
    } = this.props;

    const {
      activeSelectorOptions,
      alertOpen,
      collapsed,
      message,
      severity,
    } = this.state;

    const activeSelectorValues = Object.values(activeSelectorOptions);
    const isDisabled = activeSelectorValues.every(({ value }) => value === undefined);
    const isApplyDisabled = isDisabled || selectedCount === 0;

    const {
      brand, market, partner,
    } = activeSelectorOptions;

    const tooltipText = 'Use the drop downs below to apply '
      + 'the same brand, market and partner '
      + 'to the selected Ad Accounts.';

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') return;

      this.setState({ alertOpen: false });
    };

    const onClickApply = () => {
      onApply(brand, market, partner);
      onSelect(false);

      const newSelectorOptions = activeSelectorOptions;
      selectors.forEach((selector) => {
        newSelectorOptions[selector.name] = { value: undefined };
      });
      this.setState({
        activeSelectorOptions: newSelectorOptions,
        alertOpen: true,
        message: this.applySnackBarMessage(brand, market, partner),
        severity: 'success',
      });
    };

    return (
      <div className="adAccountBulkAssignment">
        <Portal>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={6000}
            className="adAccountBulkAssignment-alert"
            open={alertOpen}
            onClose={handleClose}
          >
            <Alert
              elevation={6}
              severity={severity}
              variant="filled"
              onClose={handleClose}
            >
              { message }
            </Alert>
          </Snackbar>
        </Portal>
        <Widget>
          <div className="adAccountBulkAssignment-headerRow u-flexRow u-justifySpaceBetween">
            <div className="u-flexRow u-flexAlignCenter">
              <div className="u-marginRightSm">
                Bulk associate brand, market, and partner
              </div>
              <Tooltip label={tooltipText} />
            </div>
            <Button className="collapsibleButton" clear sm square onClick={() => this.handleCollapsableToggle()}>
              <Icon name={collapsed ? 'chevronUp' : 'chevronDown'} />
            </Button>
          </div>
          {
            !collapsed && (
              <div className="adAccountBulkAssignment-formRow u-flexColumn">
                <div className="u-flexRow u-justifySpaceBetween u-flexAlignCenter">
                  <Checkbox
                    autoComplete="off"
                    checked={selectAllChecked}
                    label="Select All Ad Accounts"
                    onChange={() => this.onSelectAll()}
                  />
                  <div className="adAccountBulkAssignment-countContainer">
                    { selectedCount }
                    /
                    { totalCount }
                    { ' Selected' }
                  </div>
                </div>
                <div className="adAccountBulkAssignment-formData u-marginAboveSm u-justifySpaceBetween u-flexRow">
                  { this.renderSelectors() }
                </div>
                <div className="u-flexRow u-justifyFlexEnd u-marginAbove">
                  <Button
                    disabled={isApplyDisabled}
                    onClick={onClickApply}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )
          }
        </Widget>
      </div>
    );
  }
}

AdAccountBulkAssociate.propTypes = propTypes;

export default AdAccountBulkAssociate;
