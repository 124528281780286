import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Tag,
  Tooltip,
} from '@makeably/creativex-design-system';

export const NULL_VALUE = '~';

export const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

export const optionArrayProps = PropTypes.arrayOf(optionProps);

export const filterProps = PropTypes.objectOf(
  PropTypes.arrayOf(optionProps),
);

export const emptyState = (
  <div className="t-empty u-flexRow u-justifyCenter u-marginTop-16">
    No data to display
  </div>
);

export function getNullableDimension(value) {
  if (value === null || typeof value === 'undefined') {
    return {
      label: 'N/A',
      value: NULL_VALUE,
    };
  }

  return { value };
}

export function getUniqueArrayDimension(values) {
  const set = new Set(values);

  if (set.size === 0) {
    return { value: 'N/A' };
  } else if (set.size === 1) {
    return { value: Array.from(set)[0] };
  }
  return {
    label: 'Multiple',
    value: Array.from(set).join(', '),
  };
}

export function renderBetaTag() {
  return (
    <Tooltip label="If this feature has been useful to you, or if you have new use cases, please slack @Phil">
      <Tag color="blue" label="Beta" />
    </Tooltip>
  );
}

export function renderDeleteButton(onClick, disabled = false, active = false) {
  return (
    <ActionButton
      active={active}
      disabled={disabled}
      label="Delete"
      variant="destructive"
      onClick={onClick}
    />
  );
}
