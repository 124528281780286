import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Button,
  Card,
  Checkbox,
  Search,
} from '@makeably/creativex-design-system';
import {
  NULL_VALUE,
  emptyState,
  filterProps,
  getNullableDimension,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  addToast,
  addErrorToast,
} from 'components/organisms/Toasts';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { setItemsElement } from 'utilities/itemElement';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { searchItems } from 'utilities/itemSearch';
import {
  toggleProperty,
  valueIf,
} from 'utilities/object';
import {
  addFormDataArray,
  post,
} from 'utilities/requests';
import { internalReviewGuideSubscriptionsPath } from 'utilities/routes';
import { titleize } from 'utilities/string';
import {
  getPage,
  getParams,
  setParam,
} from 'utilities/url';

const definitionProps = PropTypes.shape({
  category: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  guidelineId: PropTypes.number.isRequired,
  guidelineName: PropTypes.string.isRequired,
  guidelineState: PropTypes.string.isRequired,
  definitionId: PropTypes.number,
  definitionName: PropTypes.string,
});

const subscriptionProps = PropTypes.shape({
  guidelineId: PropTypes.number.isRequired,
  definitionId: PropTypes.number,
});

const propTypes = {
  definitions: PropTypes.arrayOf(definitionProps).isRequired,
  guidelines: PropTypes.arrayOf(definitionProps).isRequired,
  reviewGuideId: PropTypes.number.isRequired,
  reviewGuideTitle: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(subscriptionProps).isRequired,
  initialFilterSelections: filterProps,
};

const defaultProps = {
  initialFilterSelections: {},
};

const ID_SEPARATOR = '-';

const filterDimensions = [
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'Guideline Name',
    value: 'guidelineName',
  },
  {
    label: 'Guideline State',
    value: 'guidelineState',
  },
  {
    label: 'Type',
    value: 'category',
  },
];

const headers = [
  {
    key: 'selected',
    label: 'Add',
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'category',
    label: 'Type',
  },
  {
    key: 'guidelineId',
    label: 'Guideline\nID',
  },
  {
    key: 'guidelineName',
    label: 'Guideline\nName',
  },
  {
    key: 'guidelineState',
    label: 'Guideline\nState',
  },
  {
    key: 'definitionId',
    label: 'Definition\nID',
  },
  {
    key: 'definitionName',
    label: 'Definition\nName',
  },
];

function getCombinedId({ guidelineId, definitionId }) {
  return `${guidelineId}${ID_SEPARATOR}${definitionId}`;
}

function getItemCombinedId({ guidelineId, definitionId }) {
  const defId = definitionId.value === NULL_VALUE ? null : definitionId.value;

  return `${guidelineId.value}${ID_SEPARATOR}${defId}`;
}

function parseId(str) {
  const value = parseInt(str);

  return Number.isNaN(value) ? null : value;
}

function parseCombinedId(id) {
  const parts = id.split(ID_SEPARATOR);
  const definitionId = parseId(parts[1]);

  return {
    guideline_id: parseId(parts[0]),
    ...valueIf(definitionId, 'guideline_detail_id', definitionId),
  };
}

function generateExisting(subscriptions) {
  return subscriptions.reduce((obj, subscription) => ({
    ...obj,
    [getCombinedId(subscription)]: true,
  }), {});
}

function getGuidelinePart(id) {
  return id.split(ID_SEPARATOR)[0];
}

function getUsedGuidelineId(id) {
  return `${getGuidelinePart(id)}-null`;
}

function getUsedDefinitionId(id) {
  return `${getGuidelinePart(id)}-*`;
}

function isGuideline(id) {
  return id.endsWith('null');
}

function getUsedId(id) {
  if (isGuideline(id)) {
    return getUsedGuidelineId(id);
  }
  return getUsedDefinitionId(id);
}

function isUsed(id, usedSet) {
  // @note: guideline-level and definition-level subscriptions
  // for the same guideline are mutually exclusive

  if (isGuideline(id)) {
    return usedSet.has(getUsedDefinitionId(id));
  }
  return usedSet.has(getUsedGuidelineId(id));
}

function generateUnavailable(records, subscriptions, selected) {
  const connected = subscriptions.map((subscription) => getCombinedId(subscription));
  const added = Object.keys(selected);

  const usedIds = [...connected, ...added].map((id) => getUsedId(id));
  const usedSet = new Set(usedIds);

  return records.reduce((obj, record) => {
    const id = getCombinedId(record);

    if (isUsed(id, usedSet)) {
      return {
        ...obj,
        [id]: true,
      };
    }
    return obj;
  }, {});
}

function getItems(records, unavailable, existing, selected, handleSelect) {
  return records.map((record) => {
    const id = getCombinedId(record);
    const isUnavailable = Boolean(unavailable[id]);
    const isSelected = Boolean(selected[id]);

    const {
      category,
      company,
      definitionId,
      definitionName,
      guidelineId,
      guidelineName,
      guidelineState,
    } = record;

    return {
      category: { value: category },
      company: { value: company },
      definitionId: getNullableDimension(definitionId),
      definitionName: getNullableDimension(definitionName),
      unavailable: { value: isUnavailable },
      guidelineId: { value: guidelineId },
      guidelineName: { value: guidelineName },
      guidelineState: {
        label: titleize(guidelineState),
        value: guidelineState,
      },
      id: { value: id },
      selected: {
        element: (
          <Checkbox
            checked={isSelected || existing[id]}
            disabled={!isSelected && isUnavailable}
            onChange={() => handleSelect(id)}
          />
        ),
        value: isSelected,
      },
    };
  });
}

function setDisabledStyles(unavailable, selected) {
  const sheet = new CSSStyleSheet();
  const ids = Object.keys(unavailable).filter((id) => !selected[id]);
  const rowIds = ids.map((id) => `tr .itemsTableRow-${id}`).join(',');

  sheet.replaceSync(`${rowIds} { background: var(--grey-100); color: var(--text-disabled) }`);
  document.adoptedStyleSheets = [sheet];
}

function findAvailable(items) {
  const usedSet = new Set();

  // note: items are checked for conflicting guideline-level or
  // definition-level ids as they are added
  return items.reduce((obj, item) => {
    const id = getItemCombinedId(item);

    if (item.unavailable.value || isUsed(id, usedSet)) {
      return obj;
    }

    usedSet.add(getUsedId(id));

    return {
      ...obj,
      [id]: true,
    };
  }, {});
}

function NewReviewGuideSubscription({
  definitions,
  guidelines,
  initialFilterSelections,
  reviewGuideId,
  reviewGuideTitle,
  subscriptions,
}) {
  const params = getParams(window);
  const [selected, setSelected] = useState({});
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [filteredItems, setFilteredItems] = useState([]);
  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'definitionName',
    asc: true,
  });
  const [sortedItems, setSortedItems] = useState([]);
  const [page, setPage] = useState(getPage(params));
  const [isAdding, setIsAdding] = useState(false);
  const selectedCount = Object.values(selected).length;

  const handleSelect = (id) => {
    setSelected((last) => toggleProperty(last, id, true));
  };

  useEffect(() => {
    const records = [...guidelines, ...definitions];
    const existing = generateExisting(subscriptions);
    const unavailable = generateUnavailable(records, subscriptions, selected);
    const allItems = getItems(records, unavailable, existing, selected, handleSelect);
    const options = getValueOptions(filterDimensions, allItems);

    setDisabledStyles(unavailable, selected);
    setItems(allItems);
    setFilterOptions(options);
  }, [definitions, guidelines, subscriptions, selected]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  useEffect(() => {
    const keys = ['definitionId', 'definitionName', 'guidelineId', 'guidelineName'];
    const found = searchItems(filteredItems, search, keys);
    setSearchedItems(setItemsElement(found));
  }, [filteredItems, search]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedItems(searchedItems.slice().sort(byKeyDir));
  }, [searchedItems, sort]);

  const handleSelectAll = () => {
    const addedIds = findAvailable(sortedItems);

    setSelected((last) => ({
      ...last,
      ...addedIds,
    }));
  };

  const handleClose = () => {
    window.location.replace(internalReviewGuideSubscriptionsPath(reviewGuideId));
  };

  const handleAdd = async () => {
    setIsAdding(true);
    const subscriptionIds = Object.keys(selected).map((id) => parseCombinedId(id));

    const formData = new FormData();
    addFormDataArray(formData, 'subscriptions', subscriptionIds);

    const { isError } = await post(internalReviewGuideSubscriptionsPath(reviewGuideId), formData);

    if (isError) {
      addErrorToast('Could not add subscriptions. Please try again later');
      setIsAdding(false);
    } else {
      addToast(`Added ${subscriptionIds.length} subscriptions`);
      handleClose();
    }
  };

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <h5>
        { `Add Subscriptions for '${reviewGuideTitle}'` }
      </h5>
      <div className="u-flexRow u-gap-16">
        <Search
          placeholder="Guideline, Definition, or IDs"
          size="large"
          value={search}
          onChange={setSearch}
        />
        <div className="u-flexRow u-alignCenter u-gap-8">
          <ItemsFilter
            dimensions={filterDimensions}
            isOpen={filterOpen}
            options={filterOptions}
            selections={filterSelections}
            onClose={() => setFilterOpen(false)}
            onOpen={() => setFilterOpen(true)}
            onSelect={handleFilterSelect}
          />
          <FilterTags
            dimensions={filterDimensions}
            selections={filterSelections}
            onClick={() => setFilterOpen(true)}
            onRemove={handleFilterSelect}
          />
        </div>
      </div>
      <div>
        <div className="u-flexRow u-alignCenter u-gap-16">
          { `${selectedCount} selected` }
          <Button
            label="Select All"
            variant="tertiary"
            onClick={handleSelectAll}
          />
          <Button
            disabled={selectedCount === 0}
            label="Select None"
            variant="tertiary"
            onClick={() => setSelected({})}
          />
        </div>
        <ItemsTable
          className="u-scrollShadowRight"
          emptyTableContent={emptyState}
          headers={headers}
          items={sortedItems}
          page={page}
          sort={sort}
          onPageChange={setPage}
          onSortChange={setSort}
        />
      </div>
      <div className="u-flexRow u-justifyEnd">
        <div className="u-flexRow u-gap-8">
          <Button
            label="Cancel"
            variant="secondary"
            onClick={handleClose}
          />
          <ActionButton
            active={isAdding}
            disabled={selectedCount === 0}
            label="Add"
            onClick={handleAdd}
          />
        </div>
      </div>
    </Card>
  );
}

NewReviewGuideSubscription.propTypes = propTypes;
NewReviewGuideSubscription.defaultProps = defaultProps;

export default NewReviewGuideSubscription;
